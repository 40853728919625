/**
 * Checks if the given input string is a valid URL.
 *
 * This function uses a regular expression to validate the format of a URL. The URL validation covers several components:
 * - Protocol: Optional, but if present must be HTTP or HTTPS.
 * - Domain: Must be a valid domain name or IP address.
 * - Port: Optional, numerical, and prefixed by a colon.
 * - Path: Optional, can include multiple segments separated by slashes.
 * - Query String: Optional, starts with a question mark followed by parameters.
 * - Fragment: Optional, starts with a hash and is followed by an identifier.
 *
 * The function returns true if the input matches the URL pattern, otherwise false.
 *
 * @param {string} input - The string to validate as a URL.
 * @returns {boolean} True if the input is a valid URL, false otherwise.
 */
export const isValidUrl = (input) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((' + // Start of group for domain/IP or path
        '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        ')'+ // End of group for domain/IP
        '(\\:\\d+)?'+ // port
        ')?' + // Make the group optional (domain/IP + port)
        '(\\/[-a-z\\d%_.~+/*]*)*'+ // path (allow '/')
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(input);
};
