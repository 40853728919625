const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URL_CUSTOMER = process.env.REACT_APP_BASE_URL_CUSTOMER
const graphUserDetails_URL = 'https://graph.microsoft.com/v1.0/me/'
const graphUserProfilePicture_URL = 'https://graph.microsoft.com/v1.0/me/photo/$value'

/**
 * Retrieves secret token based on the specified request type.
 *
 * @param {string} requestType - The type of token to retrieve ("profilePicture" or "data").
 * @returns {string | null} - The security token secret if successfully retrieved, or null if unsuccessful.
 */
export const getToken = (requestType) => {
	let secret

	// Get the token keys from localStorage
	const clientId = process.env.REACT_APP_CLIENT_ID
	const tokenKeys = localStorage.getItem(`msal.token.keys.${clientId}`)

	if (!tokenKeys) {
		console.error('No token found. Re-login!')
		return null
	}

	const tokenKeysObject = JSON.parse(tokenKeys)

	// Extract the token based on the request type
	let token
	if (requestType === 'profilePicture' || requestType === 'tenantName') {
		token = tokenKeysObject?.accessToken?.[0]
	} else if (requestType === 'data') {
		token = tokenKeysObject?.idToken?.[0]
	} else {
		console.error('Invalid token type.')
		return null
	}

	if (!token) {
		console.error('No token found. Re-login!')
		return null
	}

	const storedItem = localStorage.getItem(token)

	if (!storedItem) {
		console.error(`Token not found in localStorage.`)
		return null
	}

	// Parse the stored JSON to get the object
	const storedObject = JSON.parse(storedItem)

	// Check if the credentialType is equal to the expected type
	if (
		(requestType === 'profilePicture' || requestType === 'tenantName') &&
		storedObject.credentialType === 'AccessToken'
	) {
		secret = storedObject.secret
	} else if (requestType === 'data' && storedObject.credentialType === 'IdToken') {
		secret = storedObject.secret
	} else {
		console.log(`No matching item found or credentialType is not ${requestType}.`)
	}

	return secret
}

/**
 * Retrieves the profile picture URL, fetching it if not already stored in localStorage.
 *
 * @returns {Promise<boolean>} - A Promise resolving to true if the profile picture URL is successfully retrieved or fetched,
 * and false if an error occurs.
 */
export const profilePictureRequest = async () => {
	const imageUrl = localStorage.getItem('profilePictureUrl')

	if (!imageUrl) {
		const token = getToken('profilePicture')

		if (!token) {
			console.log('No token found. Re-login!')
			return false
		}

		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		})

		const config = {
			method: 'GET',
			headers: headers,
		}

		// console.log('Fetching profile picture from URL:', graphUserProfilePicture_URL)
		// console.log('Using token:', token)

		try {
			const response = await fetch(`${graphUserProfilePicture_URL}`, config)

			if (response.ok) {
				const imageData = await response.blob()
				const imageUrl = URL.createObjectURL(imageData)

				localStorage.setItem('profilePictureUrl', imageUrl)

				return true
			} else {
				const errorData = await response.json()
				// console.log('Error fetching profile picture:', errorData)
				// console.log('Response status:', response.status)
				// console.log('Response status text:', response.statusText)

				if (response.status === 404) {
					console.log('Profile picture not found. Setting default image.')
					
					// Set a default image or handle the missing profile picture case
					localStorage.setItem('profilePictureUrl', '')

					return true
				}
			}
		} catch (error) {
			console.error('Error occurred while fetching profile picture:', error)
		}
	}
	return false
}

/**
 * Performs a data request to the specified API endpoint using the given HTTP method and optional request body.
 *
 * @param {string} endpoint - The API endpoint to make the request to.
 * @param {string} [method="GET"] - The HTTP method for the request (default is "GET").
 * @param {object|null} [body=null] - The request body, applicable for "POST" and "PUT" methods.
 * @returns {Promise<any>} - A Promise resolving to the data returned from the API, or an error message if the request fails.
 */
export const dataRequest = async (endpoint, method = 'GET', body = null, baseURL = BASE_URL) => {
	const token = getToken('data')

	if (!token) {
		console.log('No token found. Re-login!')
		return null
	}

	const headers = new Headers({
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	})

	// console.log('📌📌 Authorization:', headers.get('Authorization'));

	const config = {
		method: method,
		headers: headers,
	}

	if (body && (method === 'POST' || method === 'PUT')) {
		config.body = JSON.stringify(body)
	}

	try {
		const response = await fetch(`${baseURL}/${endpoint}`, config)

		let data

		if (response.headers.get('Content-Type')?.includes('application/json')) {
			data = await response.json()
		} else {
			data = await response.text()
		}

		if (!response.ok) {
			console.log(data.message || 'Error occurred while fetching data.')
		}

		return data
	} catch (error) {
		console.log('Error occurred while fetching data.')
	}
}

/**
 * Fetches the tenant name from Microsoft Graph API.
 *
 * @returns {Promise<string | null>} - A Promise resolving to the tenant name if successfully retrieved, or null if unsuccessful.
 */
export const tenantNameRequest = async () => {
	const token = getToken('tenantName')

	if (!token) {
		console.error('No token found. Re-login!')
		return null
	}

	const headers = new Headers({
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	})

	try {
		const response = await fetch(graphUserDetails_URL, { headers })

		if (!response.ok) {
			const errorData = await response.json()
			console.error('Error fetching user details:', errorData.error.message)
			return null
		}

		const userData = await response.json()

		// Extract the tenant domain from the user's principal name
		const tenantDomain = userData.userPrincipalName.split('@')[1]
		return tenantDomain
	} catch (error) {
		console.error('Error occurred while fetching tenant name:', error)
		return null
	}
}

/**
 * Get customer data based on tenantId.
 *
 * @param {string} tenantId - The tenant ID to use for the request.
 * @returns {Promise<any>} - A Promise resolving to the customer data or an error message.
 */
export const getCustomerData = async () => {
	const endpoint = `GetCustomer`

	return dataRequest(endpoint, 'GET', null, BASE_URL_CUSTOMER)
}

/**
 * Get customer data based on tenantId.
 *
 * @param {string} tenantId - The tenant ID to use for the request.
 * @returns {Promise<any>} - A Promise resolving to the customer data or an error message.
 */


export const getEntitleMent = async ()=>{
	const entitlementsResponse = await dataRequest('KBIZ_HomePage_GetEntitlements')
	const results = entitlementsResponse.entitlements.filter(obj => obj.name === 'Payroll: Australia');

	if(results[0].enabled)
		{
			return true
		}else {
			return false
		}
}


export const getFeatureFromHub = async (method = 'GET',body = null)=>{
	try {
		let endpoint= 'ComandCentre_GetFeature?feature=Payroll&fields=terms'
		
		const r= await dataRequest(endpoint, 'GET', null, 'https://au-saz-wiiselive-api-homepage.azurewebsites.net/api/')
		

		return r
	} catch (error) {
		console.log('Error occurred while fetching data.')
	}
}
