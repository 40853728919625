import React, { useState, useEffect } from 'react'

import HeaderComponent from '../common/headerComponent'
import FooterComponent from '../common/footerComponent'

import { ButtonPrimary } from '../components'

import { dataRequest } from '../utils/dataRequest'
import useRefreshToken from '../hooks/useRefreshToken'

import CardContainerTitleWithButton from '../common/cardContainerTitleWithButton'

import { H1HeadingNavyBlue, H3MulishNavy, P1MulishNavyBlue, H3MulishBlue } from '../themes/typography'

const SupportPage = () => {
	useRefreshToken()

	// data
	const [kbArticlesData, setKbArticlesData] = useState(null)
	const [hubSpotStatusData, setHubSpotStatusData] = useState(null)
	const [releaseNotesData, setReleaseNotesData] = useState(null)

	const [error, setError] = useState(null)

	const fetchData = async () => {
		try {
			const kbArticles = await dataRequest('KBIZ_HomePage_KB_Articles')
			localStorage.setItem('kbArticles', JSON.stringify(kbArticles))
			setKbArticlesData(kbArticles)

			const hubSpotStatus = await dataRequest('KBIZ_HomePage_HubSpotSystemStatus')
			localStorage.setItem('hubSpotStatus', JSON.stringify(hubSpotStatus))
			setHubSpotStatusData(hubSpotStatus)

			const releaseNotes = await dataRequest('CommandCentre_GetReleaseNotes')
			localStorage.setItem('releaseNotes', JSON.stringify(releaseNotes?.releaseNotes))
			setReleaseNotesData(releaseNotes?.releaseNotes)
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		try {
			const kbArticles = localStorage.getItem('kbArticles')
			const hubSpotStatus = localStorage.getItem('hubSpotStatus')
			const releaseNotes = localStorage.getItem('releaseNotes')

			if (kbArticles && kbArticles !== 'undefined') setKbArticlesData(JSON.parse(kbArticles))
			if (hubSpotStatus && hubSpotStatus !== 'undefined') setHubSpotStatusData(JSON.parse(hubSpotStatus))
			if (releaseNotes && releaseNotes !== 'undefined') setReleaseNotesData(JSON.parse(releaseNotes))
		} catch (error) {
			console.error('Error parsing local storage data:', error)
			setError('Failed to parse local storage data')
		}

		fetchData()
	}, [])

	return (
		<div className='bg-wiiseLightBlue'>
			<HeaderComponent />

			<div className='mx-[20px] my-[80px] sm:mx-[20px] sm:my-[100px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[120px] lg:my-[120px]'>
				<div>
					<H1HeadingNavyBlue className='mb-5'>Support</H1HeadingNavyBlue>

					<P1MulishNavyBlue>
						Get in touch with your support contact to find help, or look through some of our resources.
					</P1MulishNavyBlue>
				</div>

				<div className='flex flex-wrap w-full gap-5 mt-12'>
						<div className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3 min-h-[275px] bg-white rounded-[16px] px-[24px] py-[30px] pb-[35px]'>
							{/* <h1 className='text-[35px] font-bold -mt-1'>Solutions+</h1> */}

							{/* <hr className='w-full border-wiisetLightBlue mt-6 mb-6' /> */}

							{/* <H3MulishNavy className='mb-6'>Support Contact: Solutions Plus</H3MulishNavy> */}

							<H3MulishNavy className='mb-6'>Contact your Support Partner</H3MulishNavy>

							<p className='mb-6'>
								Your IT services provider is your first point of contact for questions relating to Wiise.
							</p>

							<hr className='w-full border-wiisetLightBlue my-8' />

							<H3MulishBlue className='mb-5'>Get help quicker</H3MulishBlue>

							<H3MulishNavy className='mb-6'>Support request guidance</H3MulishNavy>

							<p className='mb-6'>
								Find out how you can get help quicker. This article provides guidance on how to raise support requests
								so that your request is efficiently resolved.
							</p>

							<ButtonPrimary
								label='Support request guidance'
								caret={true}
								route='https://docs.wiise.com/raise-a-support-services-partner-request'
								targetBlank={true}
							/>
						</div>

						<CardContainerTitleWithButton
							className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3 min-h-fit'
							title='Knowledge Base'
							subTitle='Featured guides'
							buttonTitle='See articles'
							route='https://docs.wiise.com/'
							targetBlank={true}
							data={kbArticlesData}
							dataType='KBIZ_HomePage_KB_Articles'
						/>

						<CardContainerTitleWithButton
							className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3 min-h-fit'
							title="What's new in Wiise"
							buttonTitle="See what's new"
							route='https://www.wiise.com/roadmap'
							targetBlank={true}
							divider={true}
							data={releaseNotesData}
							dataType='CommandCentre_GetReleaseNotes'
						/>

						<CardContainerTitleWithButton
							className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3 min-h-fit'
							title='Payroll Guides'
							buttonTitle='See guides'
							route='https://support.yourpayroll.com.au/hc/en-au/sections/5015251885711-Wiise'
							targetBlank={true}
							divider={true}
							bodyText='Wiise Payroll guides and documentations, powered by Employment Hero.'
							loadingAnimation={false}
						/>

						<CardContainerTitleWithButton
							className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
							title='System Status'
							buttonTitle='See updates'
							targetBlank={true}
							divider={true}
							data={hubSpotStatusData}
							dataType='KBIZ_HomePage_HubSpotSystemStatus'
						/>

						<CardContainerTitleWithButton
							className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3 min-h-fit'
							title='FAQs'
							buttonTitle='See FAQs'
							route='https://docs.wiise.com/frequently-asked-questions'
							targetBlank={true}
							divider={true}
							bodyText='Answers to your most frequently asked questions.'
							loadingAnimation={false}
						/>
				</div>
			</div>

			<FooterComponent />
		</div>
	)
}

export default SupportPage
