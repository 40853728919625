import HeaderComponent from '../common/headerComponent'
import FooterComponent from '../common/footerComponent'

import { wiiseLogoSmallBlue } from '../assets/images'

import { H1HeadingNavyBlue } from '../themes/typography'

const BotFrame = () => {
	return (
		<iframe
			src='https://copilotstudio.microsoft.com/environments/Default-0e76f719-9db0-4b75-9cad-9d38ab0555dc/bots/cree4_ronAi/webchat?__version__=2'
			style={{ width: '100%', height: '100%' }}
			title='Microsoft Copilot Studio Web Chat'
		/>
	)
}

const WiiseCopilotPage = () => {
	return (
		<div className='bg-wiiseLightBlue'>
			<HeaderComponent userEntitlements={['ft-02']} />

			<div className='mx-[20px] my-[100px] sm:mx-[20px] sm:my-[100px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[120px] lg:my-[120px]'>
				<div className='flex flex-col md:flex-row gap-5'>
					<div className='flex flex-col w-full mb-5'>
						<div className='w-[300px]'>
							<img className='w-[50px] mb-5' src={wiiseLogoSmallBlue} alt='wiiseLogoSmallBlue' />

							<H1HeadingNavyBlue className='mb-8'>Wiise Copilot</H1HeadingNavyBlue>

							<div className='mb-10'>
								<p className='mb-3'>Harness the power of AI to get help about how to use Wiise. </p>

								<p>Try asking the Wiise Copilot how to complete processes or set up features. For example:</p>
							</div>

							<div className='flex flex-col'>
								<b className='mb-3'>"How do I raise a sales order?"</b>
								<b className='mb-3'>"How do I setup payroll?"</b>
								<b className='mb-3'>"How do I perform a month end?"</b>
							</div>
						</div>
					</div>

					<div className='flex w-full items-end'>
                        <div className='flex w-[100%] h-[70vh] bg-white rounded-[8px] shadow-wiiseShadowHover overflow-hidden'>
                            <BotFrame />
                        </div>
                    </div>
				</div>
			</div>

			{/* <FooterComponent /> */}
		</div>
	)
}

export default WiiseCopilotPage
