import React from 'react'

const FeatureCommonCard = ({cardMainTitle='Main title', cardSubTitle='',  cardBody = () => {}})=>{
	return(
		<div className='bg-wiisePrimaryWhite mt-[24px] flex flex-col p-[24px] items-start rounded-[16px] shadow-wiiseSmallShadow' >
				<p className='text-wiisepNavy text-[18px] leading-[27px] font-mulish font-bold' >
					{cardMainTitle}
				</p>
				{cardSubTitle.length>=1 &&<p className='mt-[8px] text-[14px] leading-[21px] font-mulish font-normal text-wiisepNavy' > {cardSubTitle}</p>}
				{cardBody()}
		</div>
	)
}
export default FeatureCommonCard