import React from 'react';

const TailwindModal = ({ 
	isOpen, 
	button1Title='1111', 
	button1Action=()=>{}, 
	title='Basic title', 
	message1="1212 ", 
	message2='1111', 
	button2Title="", 
	button2Action=()=>{},

}) => {

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-800 opacity-50"></div>

      <div className="relative w-auto max-w-lg mx-[24px]  sm:mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white rounded-[16px] p-[40px] shadow-wiiseLargeShadowHover">
			<div className='w-[100%] flex justify-center'>
         		<div className='text-[16px] leading-[24px] font-mulish font-bold text-wiisepNavy flex '>{title}</div>
		  	</div>
			{message1.length>=1 &&
		  	<div className='w-[100%] flex justify-center mt-[16px] px-[20px] sm:px-[50px] text-center'>
		  		<div>{message1}</div>
		  	</div>
			}
			{message2.length>=1 &&
		  	<div className='w-[100%] flex justify-center mt-[16px] px-[20px] sm:px-[50px] text-center'>
		  		<div>{message2}</div>
		  	</div>
			}
			{button2Title==''?
			<div className='flex w-[100%] justify-center  mt-[24px]'>
				<div className='flex  py-[12px] px-[24px] w-[100px] bg-wiisepBlue justify-center rounded-[200px] cursor-pointer'>
					<div className='text-[14px] leading-[21px] font-mulish font-bold text-wiisePrimaryWhite' onClick={button1Action}>{button1Title}</div>
				</div>
			</div>:
			<div className='flex w-[100%] justify-center mt-[24px]'>
			<div className='flex  py-[12px] px-[24px] w-[100px] bg-wiisepBlue justify-center rounded-[200px] cursor-pointer'>
				<div className='text-[14px] leading-[21px] font-mulish font-bold text-wiisePrimaryWhite' onClick={button1Action}>{button1Title}</div>
			</div>
			<div className='flex ml-[16px] py-[12px] px-[24px] w-[100px] bg-wiiseHoverLightBlue justify-center rounded-[200px] cursor-pointer border-[1px] border-wiisetLightBlue'>
				<div className='text-[14px] leading-[21px] font-mulish font-bold text-wiisepBlue' onClick={button2Action}>{button2Title}</div>
			</div>
		</div>}
         
        </div>
      </div>
    </div>
  );
};

export default TailwindModal;
