import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import styled from 'styled-components'

import WIISELogo from '../assets/svgs/wiiseLogoSvg'
import MenuIconSVG from '../assets/svgs/menuIconSVG'
import CloseIconSVG from '../assets/svgs/closeIcon'

import { arrowBoxWhite, arrowBoxNavy } from '../assets/images'

import CaretDownWithTitle from '../styledUIElements/caretDownWithTitle'

const HeaderComponent = ({ userEntitlements = [] }) => {
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()

	const location = useLocation()

	const navigate = useNavigate()
	const modalRef = useRef()
	const [showModal, setShowModal] = useState(false)

	const openModal = () => {
		setShowModal(true)
	}

	const closeModal = () => {
		setShowModal(false)
	}

	const logout = () => {
		// Clear session storage
		window.sessionStorage.clear()

		// Clear local storage
		window.localStorage.clear()

		// Replace the current history state with the root URL
		window.history.replaceState(null, null, '/')

		navigate('/logout')
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal()
			}
		}

		if (showModal) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [showModal])

	return (
		<div className='fixed top-0 flex w-full h-[56px] bg-wiisepNavy z-[1]'>
			<div className='flex w-full items-center justify-between mx-[20px] sm:mx-[20px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw]'>
				<div className='flex items-center gap-5'>
					<a href='/'>
						<WIISELogo />
					</a>

					{userEntitlements.includes('ft-01') && location.pathname === '/academy' && (
						<div className='flex items-center font-bold h-[56px] text-white mt-1'>
							<p>Academy</p>
						</div>
					)}

					{/* 📌 DISABLED: Until release */}
					{/* 📌 ENABLES: For sales team only */}
					{userEntitlements.includes('ft-02') && (
						<div className='flex items-center font-bold h-[56px] text-white hover:bg-wiisepBlue3 px-5 cursor-pointer'>
							<a href='/wiise-copilot'>Wiise Copilot</a>
						</div>
					)}
				</div>

				<div className='flex gap-5'>
					{userEntitlements.includes('ft-01') && location.pathname === '/academy' && (
						<div className='hidden md:flex gap-5'>
							<a
								href='/'
								className='flex items-center gap-3 font-bold text-white border border-1 rounded-full px-5 py-1'
								target='_blank'
								rel='noopener noreferrer'
							>
								Wiise software
								<span className='w-full max-w-[12px]'>
									<img src={arrowBoxWhite} alt='bank' className='w-full' />
								</span>
							</a>

							<span className=''></span>
						</div>
					)}

					{activeAccount && (
						<>
							<div className='menu'>
								<CaretDownWithTitle openModal={openModal} />
							</div>

							<div className='menu-icons flex items-center justify-center w-[24px] h-[24px]'>
								{showModal ? (
									<CloseIconSVG onClick={() => closeModal()} />
								) : (
									<MenuIconSVG onClick={() => openModal()} />
								)}
							</div>
						</>
					)}

					{showModal && (
						<StyledModalContainer className='modal-container'>
							<div ref={modalRef} className='modal mr-0 sm:mr-[20px] md:mr-[80px] xl:mr-[180px] 2xl:mr-[12vw]'>
								<p className='font-bold mb-3'>{activeAccount?.name}</p>


								<p className='overflow-hidden text-ellipsis mb-3'>{activeAccount?.username}</p>

								{userEntitlements.includes('ft-01') && location.pathname === '/academy' ? (
									<div className='block md:hidden'>
										<hr className='border-wiisetLightBlue mt-5 mb-5' />

										<a
											href='/'
											className='flex items-center gap-3 font-bold mb-3'
											target='_blank'
											rel='noopener noreferrer'
										>
											Wiise software
											<span className='w-full max-w-[12px]'>
												<img src={arrowBoxNavy} alt='bank' className='w-full' />
											</span>
										</a>
									</div>
								) : (
									<>


										<a href='/product-features' className='flex items-center gap-3 font-bold mb-3'>

											Product and features
										</a>
										<a
											href='/academy'
											className='flex items-center gap-3 font-bold mb-3'
											target='_blank'
											rel='noopener noreferrer'
										>
											Wiise Academy
											<span className='w-full max-w-[12px]'>
												<img src={arrowBoxNavy} alt='bank' className='w-full' />
											</span>
										</a>

										<a
											href='https://www.wiise.com/request-licences'
											className='flex items-center gap-3 font-bold mb-3'
											target='_blank'
											rel='noopener noreferrer'
										>
											Request licences
											<span className='w-full max-w-[12px]'>
												<img src={arrowBoxNavy} alt='bank' className='w-full' />
											</span>
										</a>
										
										{/* 📌 DISABLED: Until release */}
										{/* <a href='/' className='flex items-center gap-3 font-bold mb-3'>
											Communication preferences
										</a> */}

										<a href='/support' className='flex items-center gap-3 font-bold mb-3'>
											Support
										</a>
									</>
								)}


							

							

								<hr className='border-wiisetLightBlue mt-5 mb-5' />

								<p className='text-wiisepBlue font-bold cursor-pointer hover:underline' onClick={() => logout()}>
									Sign out
								</p>
							</div>
						</StyledModalContainer>
					)}
				</div>
			</div>
		</div>
	)
}

const StyledModalContainer = styled.div`
	display: flex;
	justify-content: end;

	position: fixed;
	top: 55px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	@media screen and (max-width: 925px) {
		background-color: rgba(6, 7, 51, 0.85);
	}

	.modal {
		height: fit-content;
		background-color: #fff;
		padding: 24px;
		width: 90vw;
		height: 100vh;
		box-shadow: 15px 10px 20px rgba(69, 117, 229, 0.1);
		z-index: 5;
	}

	@media screen and (min-width: 768px) {
		.modal {
			position: relative;
			width: 268px;
			height: fit-content;
			background-color: #fff;
			border-radius: 10px;
			border: 1px solid #abc1f3;
			margin-top: 20px;
			padding: 20px;
			box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
		}

		&::before {
			content: '';
			position: absolute;
			right: calc(200px - 2px);
			top: 10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 12px 12px 12px;
			border-color: transparent transparent #abc1f3 transparent;
			z-index: 4;
		}

		&::after {
			content: '';
			position: absolute;
			right: 200px;
			top: 12px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent #fff transparent;
			z-index: 5;
		}
	}

	@media screen and (max-width: 925px) {
		&::before {
			right: calc(40px - 2px);
		}

		&::after {
			right: 40px;
		}
	}

	@media screen and (min-width: 1800px) {
		&::before {
			right: calc(13vw - 2px);
		}

		&::after {
			right: 13vw;
		}
	}

	.close {
		position: absolute;
		top: 10px;
		right: 15px;
		font-size: 20px;
		cursor: pointer;
	}
`
export default HeaderComponent
