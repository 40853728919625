const FooterComponent = () => {
	const currentYear = new Date().getFullYear()

	const version = process.env.REACT_APP_VERSION;

	return (
		<div className='relative w-full h-fit bg-wiiseLightBlue border-t-[1px] border-wiisetLightBlue'>
			<div className='footer flex md:flex-row justify-between mx-[20px] py-[20px] sm:mx-[20px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw]'>
				<div className='flex gap-8'>
					<p>Version {version}</p>

					<a href='https://www.wiise.com/privacy-policy' target='_blank' rel='noopener noreferrer'>
						Privacy Policy
					</a>

					<a href='https://www.wiise.com/terms-of-use' target='_blank' rel='noopener noreferrer'>
						Terms of use
					</a>
				</div>

				<p className='opacity-[0.5]'>© {currentYear} Wiise Software Pty Ltd. All rights reserved.</p>
			</div>
		</div>
	)
}

export default FooterComponent
