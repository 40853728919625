import CircleButtonCaretRight from '../styledUIElements/CircleButtonCaretRight'

import { isValidUrl } from '../utils/helper'

const CardButton = ({ title, bodyContent, href = '', targetBlank = false }) => {
	return (
		<div className='w-full h-full min-h-[97px] gradient-blue rounded-[16px] transition-shadow duration-300 hover:shadow-wiiseShadowHover'>
			<a
				href={isValidUrl(href) ? href : '#'}
				target={isValidUrl(href) && targetBlank ? '_blank' : ''}
				rel='noopener noreferrer'
				className='min-h-full flex flex-row justify-between md:items-center p-6'
			>
				<div className='mr-4 overflow-hidden whitespace-nowrap'>
					<h2 className='text-[16px] sm:text-[20px] text-white font-bold mb-1'>{title}</h2>
					<p className='text-white overflow-hidden text-ellipsis'>{bodyContent}</p>
				</div>
				<div className='flex items-center'>
					<CircleButtonCaretRight />
				</div>
			</a>
		</div>
	)
}

export default CardButton
