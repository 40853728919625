import React from 'react';
import { H4MulishNavy } from '../themes/typography';
import MicrosoftLogoSVG from '../assets/svgs/microsoftLogo';
import styled from 'styled-components';
import { grey, navy } from '../themes/colors';

const StyledWhiteButton = ({ handleLoginClick }) => {
    return (
        <StyledWhiteButtonContainer onClick={() => handleLoginClick()}>
            <MicrosoftLogoSVG className='mr-5'/>
            <H4MulishNavy>Sign in with Microsoft</H4MulishNavy>
        </StyledWhiteButtonContainer>
    );
};

const StyledWhiteButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${grey};
    padding: 10.5px 32px;
    border-radius: 80px;
    max-width: 253px;
    cursor: pointer;
    transition: border-color 200ms ease-in;
    
    &:hover {
        border-color: ${navy};
    }
`;

export default StyledWhiteButton;
