import { useNavigate } from 'react-router-dom'

import { caretRightIconWhite, caretRightIconBlue } from '../assets/images'

import { isValidUrl } from '../utils/helper'

const ButtonPrimary = ({
	label = 'ButtonPrimary',
	caret = false,
	caretType = '', // < or >
	background = '',
	classNames = '',
	action = 'redirect',
	route = '#',
	targetBlank = false,
	onClick,
}) => {
	return (
		<>
			{/* for animation add - !caret && 'hover:px-9 ease-in duration-200' */}
			<a
				onClick={onClick}
				href={isValidUrl(route) ? route : '#'}
				target={isValidUrl(route) && targetBlank ? '_blank' : ''}
				className={`group flex items-center justify-center ${classNames} w-fit text-center font-bold ${
					background
						? 'border-2 border-' + background + ' bg-' + background + ' text-wiisepBlue'
						: 'border-2 border-wiisepBlue bg-wiisepBlue text-white'
				} ${!caret && ''} px-8 py-3 rounded-full`}
				rel='noreferrer'
			>
				{label}

				{caret && (
					<span>
						{/* for animation add - 'group-hover:ml-4 ease-in duration-200' */}
						<img src={background ? caretRightIconBlue : caretRightIconWhite} alt='bank' className='w-[7px] ml-4' />
					</span>
				)}
			</a>
		</>
	)
}

export default ButtonPrimary
