const IconWarning = () => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24ZM12.5 4.8C13.1627 4.8 13.7 5.33726 13.7 6V13C13.7 13.6627 13.1627 14.2 12.5 14.2C11.8373 14.2 11.3 13.6627 11.3 13V6C11.3 5.33726 11.8373 4.8 12.5 4.8ZM14.1 17.6C14.1 18.4837 13.3837 19.2 12.5 19.2C11.6163 19.2 10.9 18.4837 10.9 17.6C10.9 16.7163 11.6163 16 12.5 16C13.3837 16 14.1 16.7163 14.1 17.6Z'
			fill='#FFCF52'
		/>
	</svg>
)
export default IconWarning
