import styled from "styled-components";
import { StyledButtonDiv } from "../styledComponents/StyleSheet";
import { white } from "../themes/colors";
import {
  H2MulishNavy,
  H5MulishWhite,
  P1MulishNavyBlue,
} from "../themes/typography";
import WIISELogoWhiteSVG from "../assets/svgs/wiiseLogoWhiteSVG";

const LogoutPage = () => {
  return (
    <StyledLoginPageContainer>
      <div className="min-h-screen flex items-center justify-center">
        <StyledLoginPageInnerContainer>
          <div className="flex justify-center mb-[24px]">
            <H2MulishNavy>You’ve signed out of Wiise.</H2MulishNavy>
          </div>

          <div className="xs:hidden flex justify-center mb-[24px]">
            <P1MulishNavyBlue>Please sign in to continue.</P1MulishNavyBlue>
          </div>

          <div className="flex justify-center">
            <StyledButtonDiv href="/" rel="noopener noreferrer">
              <H5MulishWhite>Return to sign in</H5MulishWhite>
            </StyledButtonDiv>
          </div>
        </StyledLoginPageInnerContainer>
      </div>

      <div style={{ justifyContent: "center", display: "flex" }}>
        <WIISELogoWhiteSVG className="logo" />
      </div>
    </StyledLoginPageContainer>
  );
};

const StyledLoginPageContainer = styled.div`
  z-index: 5;
  min-height: 100vh;

  background: rgb(115, 173, 240);
  background: radial-gradient(
    circle,
    rgba(115, 173, 240, 1) 0%,
    rgba(69, 117, 229, 1) 70%
  );

  .logo {
    position: absolute;
    justify-content: center;
    bottom: 1%;
    margin-bottom: 32px;
  }
`;

const StyledLoginPageInnerContainer = styled.div`
  margin-top: -80px;
  padding: 60px 24px;
  width: 35vw;

  background: ${white};

  border-radius: 16px;

  @media screen and (max-width: 1200px) {
    width: 40vw;
  }

  @media screen and (max-width: 826px) {
    width: 85vw;
  }

  @media screen and (min-width: 390px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
export default LogoutPage;
