import { useState, useEffect } from 'react'

import CaretRight from '../assets/svgs/caretRight'
import { wiiseLogoSmallBlue, caretRightIconBlue } from '../assets/images'

import { dataRequest } from '../utils/dataRequest'
import { isValidUrl } from '../utils/helper'

const CardContainerTitleWithButton = ({
	className = '',
	title,
	titleSize = '16px', // default title size
	subTitle = false,
	buttonTitle,
	route = '#',
	onClick, // onClick function passed as a prop
	targetBlank = false,
	divider = false,
	bodyText = '',
	data = [],
	dataType = '',
	loadingAnimation = true,
}) => {
	const [isLoading, setIsLoading] = useState(true)
	const [parsedContent, setParsedContent] = useState([])
	const [rootUrl, setRootUrl] = useState(route)

	useEffect(() => {
		try {
			if (data && data.length > 0) {
				if (dataType === 'Coassemble_GetCourses') {
					setParsedContent(data)
					setIsLoading(false)
				} else if (dataType === 'KBIZ_HomePage_KB_Articles') {
					const articlesData = JSON.parse(data)

					setParsedContent(
						articlesData.articles.map((item) => ({
							label: item.title,
							url: item.url,
						}))
					)
					setIsLoading(false)

					setRootUrl(articlesData[0]?.root_url || 'https://docs.wiise.com/')
				} else if (dataType === 'KBIZ_HomePage_HubSpotSystemStatus') {
					const statusData = JSON.parse(data)

					setParsedContent(
						statusData.map((item) => ({
							label: item.label,
							url: item.published_url,
						}))
					)
					setIsLoading(false)

					setRootUrl(statusData[0]?.root_url || rootUrl)
				} else if (dataType === 'CommandCentre_GetReleaseNotes') {
					const releaseNotesData = data

					setParsedContent(
						releaseNotesData.map((item) => ({
							label: item.title,
							url: item.url,
						}))
					)
					setIsLoading(false)

					setRootUrl(releaseNotesData[0]?.root_url || rootUrl)
				}
			} else if (data?.length === 0 || !loadingAnimation) {
				setIsLoading(false)
			}
		} catch (error) {
			console.error('Error parsing data: ', error.message)
		}
	}, [data, dataType, route])	

	const handleCourseClick = async (id) => {
		try {
			const response = await dataRequest(`Coassemble_GetTrackableLink?courseId=${id}`, 'POST')
			const newWindow = window.open(response?.trackableLink, '_blank', 'noopener,noreferrer')

			if (newWindow) newWindow.opener = null
		} catch (error) {
			console.error('Error fetching course link: ', error.message)
		}
	}

	return (
		<div
			className={`min-h-[32px] bg-white rounded-[16px] cardHoverShadow ${className} px-[30px] py-[24px] ${
				isLoading === true ? 'animatePulse' : 'opacity-100'
			}`}
		>
			{isLoading === false && (
				<>
					<div className='flex flex-row items-center justify-between'>
						<div className='flex items-center gap-6'>
							{title === 'Wiise Copilot' && (
								<img className='w-[50px]' src={wiiseLogoSmallBlue} alt='wiiseLogoSmallBlue' />
							)}

							<p className='font-bold text-wiisepNavy' style={{ fontSize: titleSize }}>
								{title}
							</p>
						</div>

						{buttonTitle && (
							<a
								onClick={onClick ? onClick : undefined}
								href={!onClick && isValidUrl(rootUrl) ? rootUrl : undefined}
								target={!onClick && targetBlank ? '_blank' : undefined}
								rel={!onClick ? 'noopener noreferrer' : undefined}
								className="flex items-center px-[24px] py-[12px] rounded-full bg-wiisepBlue transition-colors duration-200 ease-in hover:bg-wiiseBlueHover cursor-pointer"
							>
								<p className="text-white font-bold mr-2">{buttonTitle}</p>
								<CaretRight />
							</a>
						)}
					</div>

					{divider ? <hr className='w-full border-wiisetLightBlue mt-8 mb-8' /> : null}

					{subTitle ? (
						<div className='mt-5'>
							<p className='font-bold text-[16px] text-wiisepBlue'>{subTitle}</p>
						</div>
					) : null}

					<div className='styledCardContainerBody mt-5'>
						{bodyText ? (
							<p className='mb-3' dangerouslySetInnerHTML={{ __html: bodyText }} />
						) : parsedContent.length === 0 ? (
							<p className='mb-3'>-</p>
						) : dataType === 'Coassemble_GetCourses' ? (
							parsedContent.slice(0, 2).map((course) => (
								<div
									onClick={() => {
										handleCourseClick(course.id)
									}}
									key={course.id}
									className='flex items-center gap-5 w-full bg-wiiseLightBlue rounded-[16px] px-5 py-4 mb-4 cursor-pointer'
								>
									<div className='min-w-[60px] w-[60px] min-h-[60px] h-[60px] mb-auto md:mb-0'>
										<img
											src={course.card_image ?? course.image}
											alt='course'
											className='object-cover w-full h-full rounded-[8px]'
										/>
									</div>

									<div>
										<p className='font-bold mb-1 truncateLines-2'>{course.title}</p>

										{/* <p>{course.category.title}</p> */}
									</div>

									<div className='flex items-center justify-center w-[25px] ml-auto'>
										<img src={caretRightIconBlue} alt='caretRightIconBlue' className='w-[8px]' />
									</div>
								</div>
							))
						) : (
							parsedContent.slice(0, 4).map((item, index) => (
								<div key={index}>
									<a
										href={isValidUrl(item.url) ? item.url : '#'}
										target={isValidUrl(item.url) ? '_blank' : ''}
										rel='noopener noreferrer'
									>
										<div className='flex justify-between overflow-hidden whitespace-nowrap hover:opacity-[0.75]'>
											<p className='mb-3 overflow-hidden text-ellipsis mr-8'>{item.label}</p>

											<img src={caretRightIconBlue} alt='caretRightIconBlue' />
										</div>
									</a>
								</div>
							))
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default CardContainerTitleWithButton
