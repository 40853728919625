export const white = '#FFFFFF'
export const grey = '#CBD6E2'
export const lightBlue= '#E8F2FF'
export const lighterBlue='#E2EEFF'
export const tLightBlue='#ABC1F3'
export const blue='#4575E5'
export const blueHover='#2F5BC4'
export const navy= '#051359'
export const accentBlue = '#50B8F9'
export const linearGradientBlue = `linear-gradient(117deg, ${blue} 8.44%, ${blue} 52.43%, ${accentBlue} 107.81%)`
export const linearGradientButtonBlue = `linear-gradient(117deg, ${lightBlue} 8.44%, ${lightBlue} 52.43%, ${lighterBlue} 107.81%)`
export const vibrantBlue = '#50B8F9'