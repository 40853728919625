import { useState, useEffect } from 'react'

import ThreeStarSVG from '../assets/svgs/threeStarSVG'
import CircleWithTick from '../assets/svgs/circleWithTick'
import WIISEOntimeSVG from '../assets/svgs/wiiseOnTime'

const MessageCarouselComponent = ({ messages }) => {
	const [currentSlide, setCurrentSlide] = useState(0)

	const svgRender = (index) => {
		switch (index) {
			case 0:
				return <ThreeStarSVG />
			case 1:
				return <CircleWithTick />
			default:
				return <WIISEOntimeSVG />
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSlide((prevSlide) => (prevSlide === messages.length - 1 ? 0 : prevSlide + 1))
		}, 5000)

		return () => {
			clearInterval(interval) // Clean up the interval on component unmount
		}
	}, [messages.length])

	return (
		<div className='flex flex-col items-center justify-center bg-wiiseLightBlue rounded-[16px] px-[24px] sm:px-[116px] py-[32px]'>
			<div key={currentSlide} style={{ animation: 'slideIn 0.5s ease-in-out' }}>
				<div className='h-[26px] flex justify-center mb-3'>{svgRender(currentSlide)}</div>

				<div className='min-h-[45px] text-center mb-[32px]'>
					<p className='font-bold'>{messages[currentSlide]}</p>
				</div>
			</div>

			<div className='flex gap-2'>
				{messages.map((_, index) => (
					<div
						key={index}
						className={`h-[8px] w-[8px] rounded-full ${index === currentSlide ? 'bg-[#b0b4c9]' : 'bg-[#CDD0DE]'}`}
					></div>
				))}
			</div>
		</div>
	)
}

export default MessageCarouselComponent
