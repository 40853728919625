import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

import InitialsProfilePicture from "../common/InitialsProfilePictureComponent";

import CaretDown from "../assets/svgs/caretDown";

import { H5MulishWhite } from "../themes/typography";

const CaretDownWithTitle = ({ openModal }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      onClick={() => openModal()}
    >
      <InitialsProfilePicture />

      <div className="companyName flex items-center cursor-pointer">
        <H5MulishWhite className="capitalize ml-[16px] mr-[6px]">
          {activeAccount?.name ?? "Wiise"}
        </H5MulishWhite>

        <span className="ml-2">
          <CaretDown />
        </span>
      </div>
    </div>
  );
};
export default CaretDownWithTitle;
