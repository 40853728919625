import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { msalConfig } from "./authConfig";

import App from "./App";

import "./index.css";

// MSAL should be instantiated outside of the component tree to prevent it from
// being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const activeAccount = msalInstance.getActiveAccount();

  if (activeAccount) {
    msalInstance.setActiveAccount(activeAccount[0]);
  } else {
    // Handle the case where activeAccount is null
    console.error("No active account found.");
  }
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;

    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App instance={msalInstance} />
    </Router>
  </React.StrictMode>
);
