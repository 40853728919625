import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'

import { dataRequest } from '../utils/dataRequest'
import useRefreshToken from '../hooks/useRefreshToken'

import CardButton from '../common/cardButton'
import CardContainerTitleWithButton from '../common/cardContainerTitleWithButton'

import { isValidUrl } from '../utils/helper'

const HomeContent = ({ userEntitlements = [] }) => {
	useRefreshToken()

	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()

	const [error, setError] = useState(null)
	const [greeting, setGreeting] = useState('Hello')

	// Data states
	const [bcUrlData, setBcUrlData] = useState(null)
	const [hubSpotStatusData, setHubSpotStatusData] = useState(null)
	const [kbArticlesData, setKbArticlesData] = useState(null)
	const [coursesData, setCoursesData] = useState(null)

	const requestBody = {
		tenants: process.env.REACT_APP_TENANTS,
		tenantId: process.env.REACT_APP_TENANT_ID,
		applicationDomain: process.env.REACT_APP_APPLICATION_DOMAIN,
		environment: process.env.REACT_APP_ENVIRONMENT,
		environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
	}

	const handleGetTrackableLink = async (id) => {
		try {
			const response = await dataRequest(`Coassemble_GetTrackableLink?courseId=${id}`, 'POST')
			const newWindow = window.open(response?.trackableLink, '_blank', 'noopener,noreferrer')

			if (newWindow) newWindow.opener = null
		} catch (error) {
			console.error('Error fetching course link: ', error.message)
		}
	}

	const fetchData = async () => {
		try {
			const bcUrl = await dataRequest('KBIZ_HomePage_GetBCURL', 'POST', requestBody)
			setBcUrlData(bcUrl)
			localStorage.setItem('bcUrl', JSON.stringify(bcUrl))

			const hubSpotStatus = await dataRequest('KBIZ_HomePage_HubSpotSystemStatus')
			setHubSpotStatusData(hubSpotStatus)
			localStorage.setItem('hubSpotStatus', JSON.stringify(hubSpotStatus))

			const kbArticles = await dataRequest('KBIZ_HomePage_KB_Articles')
			setKbArticlesData(kbArticles)
			localStorage.setItem('kbArticles', JSON.stringify(kbArticles))

			// Check for specific entitlement ids and make the data request if necessary
			// Get Wiise Academy Courses Data
			if (userEntitlements && userEntitlements.includes('ft-01')) {
				const courses = await dataRequest('Coassemble_GetCourses?category=Academy')
				setCoursesData(courses)
				localStorage.setItem('courses', JSON.stringify(courses))
			}
		} catch (error) {
			setError(error.message)
		}
	}

	useEffect(() => {
		const currentTime = new Date()
		const currentHour = currentTime.getHours()

		if (currentHour >= 5 && currentHour < 12) {
			setGreeting('Good morning')
		} else if (currentHour >= 12 && currentHour < 17) {
			setGreeting('Good afternoon')
		} else {
			setGreeting('Good evening')
		}
	}, [])

	useEffect(() => {
		try {
			const bcUrl = localStorage.getItem('bcUrl')
			const hubSpotStatus = localStorage.getItem('hubSpotStatus')
			const kbArticles = localStorage.getItem('kbArticles')
			const courses = localStorage.getItem('courses')

			if (bcUrl && bcUrl !== 'undefined') setBcUrlData(JSON.parse(bcUrl))
			if (hubSpotStatus && hubSpotStatus !== 'undefined') setHubSpotStatusData(JSON.parse(hubSpotStatus))
			if (kbArticles && kbArticles !== 'undefined') setKbArticlesData(JSON.parse(kbArticles))
			if (courses && courses !== 'undefined') setCoursesData(JSON.parse(courses))
		} catch (error) {
			console.error('Error parsing local storage data:', error)
			setError('Failed to parse local storage data')
		}

		fetchData()
	}, [])

	return (
		<div className='mx-[20px] my-[80px] sm:mx-[20px] sm:my-[100px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[120px] lg:my-[120px]'>
			<div>
				<h1 className='text-[24px] font-bold font-modernEra mb-5'>
					{greeting}, {activeAccount?.name.split(' ')[0]}
				</h1>

				<p>Find key information and Wiise resources here on your home page.</p>
			</div>

			<div className='mainCards flex flex-wrap w-full gap-5 mt-12'>
				<div className='flex flex-col lg:flex-col md:flex-row w-full lg:w-1/2-minus-gap-3 gap-5'>
					<CardButton
						title='ERP Desktop'
						bodyContent='Manage your financials, jobs and more.'
						href={isValidUrl(bcUrlData) ? bcUrlData : 'https://businesscentral.dynamics.com/'}
						targetBlank={true}
					/>

					<CardButton
						title='Payroll'
						bodyContent='Manage your leave, pay slips, super and more.'
						href='https://wiise.yourpayroll.com.au/'
						targetBlank={true}
					/>
				</div>

				{/* 📌 DISABLED: Until release */}
				{/* 📌 ENABLES: For sales team only */}
				{userEntitlements.includes('ft-02') && (
					<CardContainerTitleWithButton
						className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
						title='Wiise Copilot'
						buttonTitle='Start a chat'
						route='/wiise-copilot'
						divider={true}
						bodyText={`Introducing Wiise Copilot! <br/><br/> Harness the power of AI to get help about how to use Wiise.`}
						loadingAnimation={false}
					/>
				)}

				{userEntitlements.includes('ft-01') && (
					<CardContainerTitleWithButton
						className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
						title='Introducing Wiise Academy!'
						titleSize='20px'
						subTitle='Your learning'
						buttonTitle='See learning'
						route='/academy'
						targetBlank={true}
						data={coursesData}
						dataType='Coassemble_GetCourses'
					/>
				)}

				<CardContainerTitleWithButton
					className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
					title='Knowledge Base'
					titleSize='20px'
					subTitle='Featured guides'
					buttonTitle='See articles'
					targetBlank={true}
					data={kbArticlesData}
					dataType='KBIZ_HomePage_KB_Articles'
				/>

				<CardContainerTitleWithButton
					className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
					title='System Status'
					buttonTitle='See updates'
					targetBlank={true}
					data={hubSpotStatusData}
					dataType='KBIZ_HomePage_HubSpotSystemStatus'
					divider={true}
				/>

				{userEntitlements.includes('cc1004') && (
					<CardContainerTitleWithButton
						className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
						title='Business Templates'
						buttonTitle='See business templates'
						// 147252 - Course ID for Business templates course page
						onClick={() => handleGetTrackableLink(147252)}
						divider={true}
						bodyText='Get a head start on your business operations policies, procedures and documents with these KPMG-designed templates.'
						loadingAnimation={false}
					/>	
				)}

				<CardContainerTitleWithButton
					className='w-full md:w-1/2-minus-gap-1.5 lg:w-1/2-minus-gap-3'
					title='Support'
					buttonTitle='Get support'
					route='/support'
					divider={true}
					bodyText='Get in touch with your partner or find help.'
					loadingAnimation={false}
				/>
			</div>
		</div>
	)
}

export default HomeContent
