import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const useRefreshToken = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const refreshToken = async () => {
      // Check if there is a signed-in account
      if (accounts.length === 0) {
        console.warn("No signed-in accounts found.");
        return;
      }

      const account = accounts[0]; // Assuming there is only one account

      const request = {
        scopes: ["openid", "profile", "User.Read"], // Add the required scopes
        account: account,
      };

      try {
        // Attempt to acquire a token silently
        const response = await instance.acquireTokenSilent(request);

        // Check if the token is expired
        const expirationTime = response.expiresOn;
        const currentTime = new Date();

        if (expirationTime && expirationTime > currentTime) {
          // Token is not expired, handle it accordingly, if needed.
          // console.log("Token is not expired.");
        } else {
          // Token is expired, attempt to refresh it
          const refreshResponse = await instance.acquireTokenSilent({
            ...request,
            forceRefresh: true, // Force refresh to get a new token
          });

          // Handle the refreshed token (e.g., update state or perform other actions), if needed.
          // console.log("Refreshed token:", refreshResponse.accessToken);
        }
      } catch (error) {
        // Handle errors, such as token renewal failure
        console.error("Token renewal failed.");

        // You can redirect the user to the login page if the token cannot be refreshed
        // or implement any other desired behavior. But for now:
        // Clear session storage
        window.sessionStorage.clear();

        // Clear local storage
        window.localStorage.clear();

        // Replace the current history state with the root URL
        window.history.replaceState(null, null, "/");
      }
    };

    refreshToken(); // Function called when component mounts
  }, [accounts, instance]);

  return null;
};

export default useRefreshToken;
