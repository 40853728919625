import HeaderComponent from '../common/headerComponent'

import { lightBlue } from '../themes/colors'

const LoadingScreen = ({ activeAccount, userEntitlements }) => {
	return (
		<div className='w-[100vw] h-[100%]' style={{ background: lightBlue }}>
			<HeaderComponent userEntitlements={userEntitlements} />

			{/* {activeAccount?.username ? 'Loading ...' : 'Signing in ...'} */}

			{/* Old loader */}
			{/* <div className='mx-[20px] my-[85px] sm:mx-[20px] sm:my-[105px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[125px] lg:my-[125px]'>
				<div>
					<div className='w-full sm:w-[300px] h-[30px] bg-white rounded-[16px] cardHoverShadow animatePulse mb-5'></div>

					<div className='w-full sm:w-[500px] h-[24px] bg-white rounded-[16px] cardHoverShadow animatePulse'></div>
				</div>
			</div> */}

			<div className='flex align-center justify-center mx-[20px] my-[180px] sm:mx-[20px] sm:my-[180px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[240px] lg:my-[240px]'>
				<span className="loadingScreenSpinner"></span>
			</div>
		</div>
	)
}

export default LoadingScreen