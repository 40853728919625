import HeaderComponent from '../common/headerComponent'
import React, {useState} from 'react'
import FooterComponent from '../common/footerComponent'

import { CaretSmallLeftSVG, MinusIcon, PlusIcon, CaretDownNavy, CaretUpNavy } from '../assets/images'
import TickAndText from '../common/tickAndText'
import { useNavigate } from 'react-router-dom'
import { getFeatureFromHub } from '../utils/dataRequest'


const WiisePayrollPage=({userEntitlements=[]})=>{
	const navigate = useNavigate()
	const [selectedOption, setSelectedOption] = useState('Australia');
	const [showMoreInfo, setShowMoreInfo] = useState(false)
	const [total, setTotal]=useState({
		tile1:1,
		tile2:0,
		tile3:0
	})
	const {tile1, tile2, tile3} =total

	const checkAndNavigate=async()=>{
		const r= await getFeatureFromHub()

		if(r.terms_accepted =='true')
		{
			navigate('/wiise-payroll-enabled')

		}else{
			navigate('/wiise-payroll-tcs')
		}

	}


	const solutions = ['Deductions', 'Timesheet reporting', 'Reporting', 'Employee self-service', 'Automated pay, leave and termination (ETP) calculations', 'Cost centre allocation and breakdown', 'Expenses', 'Salary sacrifice handling', 'User dashboard', 'Approvals and workflows', 'Automated super payments','Access to prior years information']
	return(
		<>
		<HeaderComponent userEntitlements={userEntitlements} />
		<div className='mx-[20px] my-[80px] sm:mx-[20px] sm:my-[100px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[120px] lg:my-[120px]'>
			<a className='flex items-baseline' href='/product-features'>
				<img src={CaretSmallLeftSVG} alt='caretLeftSmallIconBlue'/> <p className='text-wiisepBlue text-[12px] ml-[8px] leading-[18px] font-bold cursor-pointer hover:underline'>Product and features</p>
			</a>
			<div className='text-[22px] leading-[33px] sm:text-[24px] sm:leading-[36px] mt-[16px] sm:flex sm:justify-center xs:justify-start text-wiisepNavy font-bold font-modernEra '>
				Wiise Payroll Standard
			</div>
			<div className='text-[16px] leading-[24px] sm:text-[20px] sm:leading-[30px] mt-[24px] sm:mt-[42px] text-wiisepNavy font-bold font-Mulish'>
				Standard inclusions
			</div>
			<div className='bg-wiisePrimaryWhite mt-[16px] sm:mt-[24px] flex flex-col p-[24px] items-start rounded-[16px] shadow-wiiseSmallShadow' >
				<p>
				Get everything you need to pay your employees accurately and effortlessly in one integrated solution including:
				</p>
				<div className="grid grid-cols-1 sm:grid-cols-2 sm:mr-[25px] gap-x-[25px] mt-[24px] ">
					{solutions.map((s, index) => (
						
						<TickAndText title={s} />
						
					))}
				</div>
				
				
			</div>
			<div>
					<div className='text-[16px] leading-[24px] sm:text-[20px] sm:leading-[30px] mt-[24px] sm:mt-[40px] text-wiisepNavy font-bold font-Mulish'>Indicative pricing</div>
					<p className='text-[14px] leading-[21px] mt-[8px] font-normal font-Mulish text-wiisepNavy'> 
					Pricing is indicative only. Your use of Wiise Payroll is subject to the Terms and Conditions that apply to your use of Wiise. Payroll is billed monthly in arrears based on active employees as described below.
					</p>
			</div>
			<div className='bg-wiisePrimaryWhite mt-[16px] sm:mt-[24px] flex flex-col p-[24px] items-start rounded-[16px] shadow-wiiseSmallShadow' >

				<div className='w-full'>
					<div className="flex items-center space-x-2 justify-end">
						<label for="options" className="text-[14px] leading-[21px] mt-[8px] font-bold font-Mulish text-wiisepNavy">Location:</label>
						<div className="relative">
						<select 
							id="options" 
							name="options" 
							className="appearance-none text-[14px] cursor-pointer leading-[21px] mt-[8px] font-bold font-Mulish text-wiisepBlue bg-white  pr-6 "
							value={selectedOption}
							onChange={(event)=>setSelectedOption(event.target.value)}
							>
      						<option>Australia</option>
      						<option>New Zealand</option>
    					</select>		
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pt-[6px]">
								<svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
									<path d="M4.92856 1.21822L2.97665 4.25559C2.92787 4.33 2.85824 4.3917 2.77465 4.43458C2.69106 4.47745 2.59642 4.5 2.50011 4.5C2.4038 4.5 2.30916 4.47745 2.22557 4.43458C2.14198 4.3917 2.07235 4.33 2.02357 4.25559L0.0716644 1.21822C0.0247328 1.14624 0 1.06438 0 0.981022C0 0.897662 0.0247328 0.815801 0.0716644 0.743825C0.119798 0.668947 0.189309 0.606899 0.273056 0.564053C0.356804 0.521208 0.451771 0.499109 0.548202 0.500027H4.45202C4.54838 0.49918 4.64325 0.521313 4.72691 0.564155C4.81058 0.606997 4.88002 0.669006 4.92811 0.743825C4.97511 0.815765 4.99992 0.897608 5 0.980968C5.00008 1.06433 4.97542 1.14621 4.92856 1.21822Z" fill="#4575E5"/>
								</svg>
							</div>
						</div>
					</div>


				</div>
				<hr className='w-full border-wiisetLightBlue mt-[24px] mb-[24px]' />
				<div className='sm:flex sm:flex-row w-full'> 
					<div>
						<p className='text-[16px] leading-[24px] text-wiisepNavy font-bold font-mulish'>Number of active employees </p>
						<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish mt-[8px]'>{selectedOption =='Australia'?'$4':'$4.5'} per active employee per pay run </p>
					</div>
					<div className='flex-1'></div>
					<div className='flex justify-end mt-[16px] sm:mt-[0px] '>
						<div className='w-[200px] py-[12px] px-[24px] bg-wiiseHoverLightBlue flex justify-between items-center border rounded-[8px] border-wiiseSecondaryGrey'>
							<img src={MinusIcon} alt="-" className='cursor-pointer' 
							onClick={()=>
								{
								if(tile1>1)
									{
									setTotal({...total, tile1:tile1-1})
									}
									}}
								/>
							<p>{tile1}</p>
							<img src={PlusIcon} alt="+" className='cursor-pointer' onClick={()=>setTotal({...total, tile1:tile1+1})}/>
						</div>
					</div>
				</div>
				<hr className='w-full border-wiisetLightBlue mt-[24px] mb-[24px]' />
				<div className='sm:flex sm:flex-row w-full'> 
					<div>
						<p className='text-[16px] leading-[24px] text-wiisepNavy font-bold font-mulish'>SMS notifications </p>
						<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish mt-[8px]'>{selectedOption =='Australia'?'$0.15':'$0.20'} per SMS </p>
					</div>
					<div className='flex-1'></div>
					<div className='flex justify-end mt-[16px] sm:mt-[0px] '>
						<div className='w-[200px] py-[12px] px-[24px] bg-wiiseHoverLightBlue flex justify-between items-center border rounded-[8px] border-wiiseSecondaryGrey'>
							<img src={MinusIcon} alt="-" className='cursor-pointer' onClick={()=>
								{
								if(tile2>=1)
									{
									setTotal({...total, tile2:tile2-1})
									}
									}}
								/>
							<p>{tile2}</p>
							<img src={PlusIcon} alt="+" className='cursor-pointer' onClick={()=>setTotal({...total, tile2:tile2+1})}/>
						</div>
					</div>
				</div>
				{selectedOption=='Australia' &&
				<>
				<hr className='w-full border-wiisetLightBlue mt-[24px] mb-[24px]' />
				<div className='sm:flex sm:flex-row w-full'> 
					<div>
						<p className='text-[16px] leading-[24px] text-wiisepNavy font-bold font-mulish'>Superannuation payment</p>
						<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish mt-[8px]'>$0.25 per fund transfer</p>
					</div>
					<div className='flex-1'></div>
					<div className='flex justify-end mt-[16px] sm:mt-[0px] '>
						<div className='w-[200px] py-[12px] px-[24px] bg-wiiseHoverLightBlue flex justify-between items-center border rounded-[8px] border-wiiseSecondaryGrey'>
						<img src={MinusIcon} alt="-" className='cursor-pointer' onClick={()=>
								{
								if(tile3>=1)
									{
									setTotal({...total, tile3:tile3-1})
									}
									}}
								/>
							<p>{tile3}</p>
							<img src={PlusIcon} alt="+" className='cursor-pointer' onClick={()=>setTotal({...total, tile3:tile3+1})}/>
						</div>
					</div>
				</div>
				</>
					}
				<hr className='w-full border-wiisetLightBlue mt-[24px] mb-[24px]' />
				<div className='flex w-full justify-end'>
					<div>
						<p className='text-[16px] leading-[24px] text-wiisepNavy font-bold font-mulish'>Indicative pricing: ${parseFloat( tile1*(selectedOption === 'Australia'?4:4.5) +tile2*(selectedOption==='Australia'?0.15:0.2)+tile3*(selectedOption=='Australia'?0.25:0)).toFixed(2)} AUD</p>
						<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish mt-[8px] text-right'> Excludes GST</p>
						<div className='flex justify-end'>
							<div className='flex  py-[12px] px-[24px] w-[100px] bg-wiisepBlue justify-center rounded-[200px] mt-[24px] cursor-pointer' onClick={()=>checkAndNavigate()}> 
								<p className='text-[14px] leading-[21px] text-wiisePrimaryWhite font-normal font-mulish'>Continue</p>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='mt-[16px] xs:mt-[24px]'>
				<div className='flex'>
					<div className='text-[14px] leading-[21px] text-wiisepNavy font-bold font-mulish'>Active employees</div>
					<img src={showMoreInfo?CaretDownNavy:CaretUpNavy} className='ml-[8px]'alt="^" onClick={()=>setShowMoreInfo(!showMoreInfo)}/>
				</div>
				{showMoreInfo &&
				<div>
					<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish mt-[16px] xs:mt-[24px]'>
					Our fee for accessing Employment Hero Payroll is charged per active employee per month. An active employee is defined as any individual (set up as an employee or contractor or anything else in the business) included in one or more of the following activities within the billing period:
					</p>
					<ul className="list-disc pl-5 mt-[24px] mb-[24px] ml-[8px] text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish">
						<li>A pay run that has been finalised, even if that pay run is then unlocked.</li>
						<li>A timesheet that has been approved, even if that timesheet is then unapproved or rejected.</li>
						<li>An expense request that has been approved, even if that expense request is then unapproved or declined.</li>
						<li>A leave request that has been approved, even if that leave request is then unapproved or declined,</li>
						<li>A shift that has been published, even if that shift is then unpublished.</li>
					</ul>
					<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish'>
					These activities will be captured within the billing period when the action is undertaken in that billing period. For e.g., if a pay run is first finalised on the 10th of July, regardless of the pay period date range, or a timesheet dated in May is first approved on the 12th of July, they will be included in the invoice sent on the 1st of August that pertains to the 1st July to 31st July billing period.

					</p>
					<p className='text-[14px] leading-[21px] text-wiisepNavy font-normal font-mulish mt-[16px]'>
					An important note regarding active users: each active user count will be unique. Specifically, if an employee is included in both finalised pay runs and approved timesheets, that employee will only be counted as a one-active user on the invoice. In addition to the per-activated employee fee, the other billable items include SMS notifications and super-processing fees.
					</p>
				</div>}
			</div>


		</div>
		
		</>
	)
}
export default WiisePayrollPage