
import HeaderComponent from '../common/headerComponent'
import FooterComponent from '../common/footerComponent'
import { CheckCircle } from '../assets/images'
import { useMsal } from '@azure/msal-react'
import { CaretSmallLeftSVG } from '../assets/images'
const WiisePayrollEnabled =({userEntitlements=[]})=>{
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()
	console.log(activeAccount.tenantId)
	const bcUrl = JSON.parse(localStorage.getItem('bcUrl'))
	console.log(bcUrl)
	

	return(
		<>
		<HeaderComponent userEntitlements={userEntitlements} />
		<div className='mx-[20px] my-[80px] sm:mx-[20px] sm:my-[100px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[120px] lg:my-[120px]'>
			<a className='flex items-baseline' href='/product-features'>
				<img src={CaretSmallLeftSVG} alt='caretLeftSmallIconBlue'/> <p className='text-wiisepBlue text-[12px] ml-[8px] leading-[18px] font-bold cursor-pointer hover:underline'>Product and Features</p>
			</a>
			<div className='flex w-full justify-center mt-[16px] text-center h-[65vh]'>
				
				
				<div>
					<div className='flex justify-center mb-[16px]'><img src={CheckCircle} alt='C'/></div>
					<p className='text-[22px] leading-[33px] sm:text-[24px] sm:leading-[36px] font-bold font-modernEra text-wiisepNavy mb-[24px]'>Payroll enabled</p>
					<p className='text-[14px] leading-[21px] font-normal font-Mulish text-wiisepNavy mb-[16px]'>You've successfully enabled Wiise Payroll!</p>
					<p className='text-[14px] leading-[21px] font-normal font-Mulish text-wiisepNavy mb-[40px]'>Move into your ERP to complete set up.</p>
					<div className='flex justify-center'>
					<div className='flex bg-wiisePrimaryWhite px-[24px] py-[12px] rounded-[200px]' >
					<a href={`${bcUrl}?page=16034642`}>
						<p className='text-[14px] leading-[21px] font-bold font-Mulish text-wiisepBlue'>Complete set up</p>
						</a>
					</div>
					</div>
				</div>
				
			</div>
			
		</div>
		
		{/* <FooterComponent /> */}
		</>
		// https://businesscentral.dynamics.com/0c3fc16d-26bd-4a19-8875-370bed9600f4/
		// Sandbox?page=16034642
		
	)
}
export default WiisePayrollEnabled