import { useMsal } from '@azure/msal-react'

import HeaderComponent from '../common/headerComponent'
import FooterComponent from '../common/footerComponent'

import { lightBlue } from '../themes/colors'

import { ButtonPrimary } from '../components'

import { PageNotFound } from '../assets/images'

import { H1HeadingNavyBlue } from '../themes/typography'

const PageNotFoundPage = () => {
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()

	return (
		<div style={{ background: lightBlue }}>
			<HeaderComponent />

			<div>
				<div className='min-h-[67vh] relative flex items-center justify-center mx-[20px] my-[80px] sm:mx-[20px] sm:my-[100px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] md:my-[120px] lg:my-[120px]'>
					<div className='absolute top-[150px] sm:top-0'>
						<div className='w-full min-w-[45vw]'>
							<img src={PageNotFound} alt='bank' className='w-full' />
						</div>
					</div>

					<div className='flex flex-col items-center mt-0 sm:mt-[5vh] z-10'>
						<H1HeadingNavyBlue className='mb-[16px]'>We're sorry!</H1HeadingNavyBlue>

						<p className='mb-[48px]'>We couldn’t find what you’re looking for.</p>

						<ButtonPrimary label={`${activeAccount ? 'Return to your dashboard' : 'Return to sign in'}`} caret={true} route='/' />
					</div>
				</div>
			</div>

			<FooterComponent />
		</div>
	)
}

export default PageNotFoundPage
