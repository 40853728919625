import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'

import { ProfilePictureContainer, ProfilePicture } from '../styledComponents/StyleSheet'

import { profilePictureRequest } from '../utils/dataRequest'

const InitialsProfilePicture = () => {
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()

	const [imageUrl, setImageUrl] = useState('')
	const [userIntials, setUserIntials] = useState(null)

	const getInitials = () => {
		let userIntials = ''

		if (activeAccount) {
			const names = activeAccount.name.split(' ')

			if (names.length === 1) {
				// If only one name, use the initial of the first name
				userIntials = names[0].charAt(0).toUpperCase()
			} else if (names.length > 1) {
				// If more than one name, use the initials of the first and last names
				const firstNameInitial = names[0].charAt(0).toUpperCase()
				const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase()

				userIntials = `${firstNameInitial}${lastNameInitial}`
			}
		}

		setUserIntials(userIntials)
	}

	const fetchProfilePicture = async () => {
		try {
			if (await profilePictureRequest()) {
				setImageUrl(localStorage.getItem('profilePictureUrl'))
			}
		} catch (error) {
			console.log('Error occurred while fetching profile picture.')
		}
	}

	useEffect(() => {
		getInitials()
		fetchProfilePicture()
	}, [])

	return (
		<ProfilePictureContainer>
			{imageUrl != '' ? <ProfilePicture src={imageUrl} alt='' /> : <p>{userIntials}</p>}
		</ProfilePictureContainer>
	)
}

export default InitialsProfilePicture
