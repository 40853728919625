import React from 'react'

const CaretRight=()=>{
	return(
		<svg
		width={13}
		height={13}
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		
	  >
		<g clipPath="url(#clip0_3045_13385)">
		  <path
			d="M5.03711 2.78711L8.78711 6.53711L5.03711 10.2871"
			stroke="white"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		  />
		</g>
		
	  </svg>

	)
}
export default CaretRight