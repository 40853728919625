import React from "react";
import styled from "styled-components";
import {
  blue,
  blueHover,
  grey,
  linearGradientBlue,
  tLightBlue,
  white,
} from "../themes/colors";

export const StyledCardContainersDiv = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
  }

  @media screen and (min-width: 1440px) {
    display: unset;
    width: 50%;
  }
`;

export const StyledButtonDiv = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 200px;
  background-color: ${blue};
  transition: background-color 200ms ease-in;

  &:hover {
    background-color: ${blueHover};
  }
`;

export const ProfilePictureContainer = styled.div`
  width: 32px;
  height: 32px;
  background: ${grey};
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  background: ${grey};
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
`;
