import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import WelcomePage from './WelcomePage'
import LoadingScreen from '../common/LoadingScreen'

import HeaderComponent from '../common/headerComponent'
import HomeContent from '../components/HomeContentComponent'

import FooterComponent from '../common/footerComponent'

import { dataRequest } from '../utils/dataRequest'

const HomePage = ({ userEntitlements = [] }) => {
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()

	const navigate = useNavigate()

	const [isFormSubmitted, setIsFormSubmitted] = useState(null)
	const [isAccountFormSubmitted, setIsAccountFormSubmitted] = useState(null)

	const fetchData = async () => {
		// Check if active user has access
		// sanitize/normalize username/email
		let activeAccountEmail = (activeAccount?.username).toLowerCase()
		const response = await dataRequest(`KBIZ_HomePage_CheckHubSpotUser?email=${activeAccountEmail}`)

		setIsFormSubmitted(response.formSubmitted)
		setIsAccountFormSubmitted(response.accountOnboardingFormSubmitted)
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			{(!userEntitlements.includes('cc1011') && !userEntitlements.includes('cc1010')) || 
			(!userEntitlements.includes('cc1011') && isAccountFormSubmitted !== null && isAccountFormSubmitted === true) ||
			(isFormSubmitted !== null && isFormSubmitted === true) ? (
				<>
					<HeaderComponent userEntitlements={userEntitlements} />

					<HomeContent userEntitlements={userEntitlements} />

					<FooterComponent />
				</>
			) : (userEntitlements.includes('cc1011') || userEntitlements.includes('cc1010')) && 
				isFormSubmitted !== null && isFormSubmitted === false || 
				isAccountFormSubmitted !== null && isAccountFormSubmitted === false ? ( 
					<WelcomePage userEntitlements={userEntitlements} isFormSubmitted={isFormSubmitted} isAccountFormSubmitted={isAccountFormSubmitted} />
			) : (
				<LoadingScreen activeAccount={activeAccount} />
			)}
		</>
	)
}

export default HomePage
