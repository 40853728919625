import * as React from "react";
const CircleWithTick = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3290_3305)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0001 12C21.0001 16.9706 16.9706 21.0001 12 21.0001C7.02945 21.0001 3 16.9706 3 12C3 7.02945 7.02945 3 12 3C16.9706 3 21.0001 7.02945 21.0001 12ZM16.6429 10.2925C16.9425 10.0065 16.9536 9.53176 16.6676 9.23213C16.3816 8.93251 15.9068 8.92146 15.6072 9.20747L10.625 13.9632L8.39288 11.8325C8.09326 11.5465 7.61851 11.5575 7.33251 11.8571C7.0465 12.1568 7.05754 12.6315 7.35716 12.9175L10.1071 15.5425C10.397 15.8192 10.853 15.8192 11.1429 15.5425L16.6429 10.2925Z"
        fill="#4575E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_3290_3305">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CircleWithTick;
