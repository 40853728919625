import { useState, useEffect } from 'react'

import { StyledButtonDiv } from '../styledComponents/StyleSheet'
import { H2MulishNavy, H5MulishWhite } from '../themes/typography'

import IconWarning from '../assets/svgs/iconWarning'
import WIISELogoWhiteSVG from '../assets/svgs/wiiseLogoWhiteSVG'

const MessageModal = ({ heading='', body='', type='oopsError' }) => {
	const [modalMessage, setModalMessage] = useState({heading: '', body: ''})

	const logout = () => {
		// Clear session storage
		window.sessionStorage.clear()

		// Clear local storage
		window.localStorage.clear()

		// Replace the current history state with the root URL
		window.history.replaceState(null, null, '/')
	}

	useEffect(() => {
		// Set modal message
		if (type === 'permissionsError') {
			setModalMessage({
				heading: 'Request for permissions',
				body: 'You do not have the correct permissions to access the Wiise Command Centre. Please contact your administrator.',
			})
		} else if (type === 'configurationsError') {
			setModalMessage({
				heading: 'Request for configurations',
				body: 'You do not have the correct configurations to access the Wiise Command Centre. Please contact your administrator.',
			})
		} else if (type === 'oopsError') {
			setModalMessage({
				heading: 'Oops! Something Went Wrong',
				body: 'An error occurred. Please sign in again to continue. If the problem persists, contact your administrator.',
			})
		} else {
			setModalMessage({
				heading: 'Oops! Something Went Wrong',
				body: 'An error occurred. Please sign in again to continue. If the problem persists, contact your administrator.',
			})
		}
	}, [])

	return (
		<div
			className='fixed w-[100vw] h-[100vh] flex flex-col items-center justify-center z-10'
			style={{ background: 'radial-gradient(circle, rgba(115, 173, 240, 1) 0%, rgba(69, 117, 229, 1) 70%)' }}
		>
			<div className='w-[85vw] md:w-[55vw] lg:w-[45vw] xl:w-[35vw] text-center bg-white rounded-[16px] -mt-[10vh] p-12'>
				<div className='flex items-center justify-center gap-3 mb-[20px]'>
					<IconWarning />

					<H2MulishNavy>{heading !== '' ? heading : modalMessage.heading}</H2MulishNavy>
				</div>

				<div className='flex justify-center text-center mb-[32px]'>
					<p className='w-full sm:w-[60%] md:w-[70%] xl:w-[65%]'>
						{body !== '' ? body : modalMessage.body}
					</p>
				</div>

				<div className='flex justify-center'>
					<StyledButtonDiv
						onClick={() => {
							logout()
						}}
						href='/'
						rel='noopener noreferrer'
					>
						<H5MulishWhite>Return to sign in</H5MulishWhite>
					</StyledButtonDiv>
				</div>
			</div>

			<div style={{ justifyContent: 'center', display: 'flex' }}>
				<WIISELogoWhiteSVG className='absolute justify-center bottom-12' />
			</div>
		</div>
	)
}

export default MessageModal
