import { useMsal } from '@azure/msal-react'

import MessageCarouselComponent from '../common/messageCarousel'

import StyledWhiteButton from '../styledComponents/StyledWhiteButton'
import WIISELogoWhiteSVG from '../assets/svgs/wiiseLogoWhiteSVG'

import { loginRequest } from '../authConfig'

const LoginPage = () => {
	const { instance } = useMsal()

	const version = process.env.REACT_APP_VERSION;

	const handleLogin = () => {
		instance
			.loginRedirect({
				...loginRequest,
			})
			.catch((error) => console.log('Error occurred while logging in.'))
	}

	const carouselContent = [
		'Industry-based ERP built for Australian businesses, created by KPMG.',
		'Learning modules and documentation to help you get the best out of our product.',
		'Advanced warehouse functions in the palm of your hand with our OnTime app.',
	]

	return (
		<div
			style={{ background: 'radial-gradient(circle, rgba(115, 173, 240, 1) 0%, rgba(69, 117, 229, 1) 70%)' }}
			className='h-[100vh] flex items-center justify-center'
		>
			<div className='w-[627px] flex flex-col items-center justify-center bg-white rounded-[16px] m-[24px] -mt-[10vh] p-[24px] pt-[40px] sm:pt-[56px]'>
				<h1 className='text-[16px] sm:text-[20px] font-bold mb-8'>Welcome to Wiise!</h1>

				<p className='mb-8'>Please sign in to continue.</p>

				<div className='mb-12'>
					<StyledWhiteButton handleLoginClick={handleLogin} />
				</div>

				<MessageCarouselComponent messages={carouselContent} />

				<p className='mt-6 text-[12px]'>Version {version}</p>
			</div>

			<div className='fixed bottom-12 w-[100vw] flex justify-center'>
				<WIISELogoWhiteSVG/>
			</div>
		</div>
	)
}

export default LoginPage
