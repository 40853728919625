import React from 'react'

import { styled } from 'styled-components'
import CaretRight from '../assets/svgs/caretRight'
const CircleButtonCaretRight = ()=>{
	return(
		
            <StyledCaretRightContainer>
                
				<CaretRight/>
            </StyledCaretRightContainer>
            
      
	)
	
}

const StyledCaretRightContainer = styled.div`
border-radius: 80px;
background: rgba(255, 255, 255,0.2);
align-items:center;
justify-content:center;
display:flex;
width:47px;
height:47px;

  @media screen and (min-width: 826px) {
	width:67px;
	height:67px;
  }




`

export default CircleButtonCaretRight