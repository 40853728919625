import styled from "styled-components";
import { blue, navy, white } from "./colors";
// import React from 'react'
export const H1HeadingNavyBlue = styled.h1`
  font-family: "Modern Era";
  font-size: 22px;
  font-weight: 700;
  color: ${navy};
  
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
`;

export const P1MulishNavyBlue = styled.p`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  color: ${navy};
`;

export const P2MulishNavyBlue = styled.p`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  color: ${navy};
`;

export const H2MulishNavy = styled.h2`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  color: ${navy};

  @media screen and (min-width: 480px) {
    font-size: 20px;
  }
`;

export const H2ModernEraNavy = styled.h2`
  font-family: Modern Era;
  font-size: 16px;
  font-weight: 700;
  color: ${navy};

  @media screen and (min-width: 480px) {
    font-size: 20px;
  }
`;

export const H5MulishWhite = styled.h5`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  color: ${white};

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const H4MulishWhite = styled.h4`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  color: ${white};
`;
export const H4MulishNavy = styled.h4`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  color: ${navy};

  @media screen and (max-width: 352px) {
    font-size: 12px;
  }
`;

export const H3MulishNavy = styled.h3`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  color: ${navy};
`;

export const H3MulishBlue = styled.h3`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  color: ${blue};
`;
