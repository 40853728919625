import React, { useState, useEffect } from 'react'
import HeaderComponent from '../common/headerComponent'
import FooterComponent from '../common/footerComponent'
import { clockWhite } from '../assets/images'
import { dataRequest } from '../utils/dataRequest'

import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import LoadingScreen from '../common/LoadingScreen'

const AcademyPage = () => {
	const { instance, accounts } = useMsal()
	const activeAccount = instance.getActiveAccount()

	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState(null)
	const [coursesData, setCoursesData] = useState([])

	const fetchData = async () => {
		try {
			const courses = await dataRequest('Coassemble_GetCourses?category=Academy')
			setCoursesData(courses)
			localStorage.setItem('courses', JSON.stringify(courses))

			setIsLoading(false)
		} catch (error) {
			setError(error.message)
		}
	}

	const handleCourseClick = async (id) => {
		try {
			const response = await dataRequest(`Coassemble_GetTrackableLink?courseId=${id}`, 'POST')
			const newWindow = window.open(response?.trackableLink, '_blank', 'noopener,noreferrer')

			if (newWindow) newWindow.opener = null
		} catch (error) {
			console.error('Error fetching course link: ', error.message)
		}
	}

	useEffect(() => {
		if (!accounts.length) {
			instance
				.loginRedirect({
					...loginRequest,
					redirectUri: window.location.href, // Ensure redirection back to the current URL
				})
				.catch((e) => {
					console.error(e)
				})
		} else {
			try {
				const courses = localStorage.getItem('courses')

				if (courses && courses !== 'undefined') { 
					setCoursesData(JSON.parse(courses))
					
					setIsLoading(false)
				}
			} catch (error) {
				console.error('Error parsing local storage data:', error)
				setError('Failed to parse local storage data')
			}

			fetchData()
		}
	}, [accounts, instance])

	return (
		<>
			{activeAccount ? (
				<div className='bg-wiiseLightBlue'>
					<HeaderComponent userEntitlements={['ft-01']} />

					<div className='gradient-blue h-[180px] flex flex-col items-center justify-center text-center mt-[56px] px-10 py-10'>
						<h1 className='text-white text-[24px] font-bold font-modernEra mb-5'>Wiise Academy</h1>
						<p className='text-white'>Find courses created by our team, to support your Wiise learning.</p>
					</div>

					<div className='min-h-[calc(100vh-200px-83px)] sm:min-h-[calc(100vh-200px-83px)] md:min-h-[calc(100vh-240px-62px)] lg:min-h-[calc(100vh-240px-62px)] mx-[20px] sm:mx-[20px] md:mx-[80px] xl:mx-[180px] 2xl:mx-[12vw] my-[48px] sm:my-[48px] md:my-[48px] lg:my-[48px]'>
						<div className='flex flex-wrap gap-5'>
							{isLoading === false && coursesData.length > 0 ? (
								coursesData.map((course) => (
									<div
										key={course.id}
										className='w-full md:w-1/3 h-[300px] bg-white rounded-[16px] overflow-hidden md:w-1/3-minus-gap-2 lg:w-1/3-minus-gap-2 cardHoverShadow cursor-pointer'
										onClick={() => handleCourseClick(course.id)}
									>
										<div className='relative w-full h-[190px] overflow-hidden'>
											<img src={course.card_image} alt='course' className='object-cover w-full h-[190px]' />

											<div className='absolute bottom-0 w-full flex bg-wiisepNavy900 bg-opacity-[0.8] text-white px-8 py-3'>
												<span className='flex'>
													Course<span className='mx-3'>●</span>
													<img src={clockWhite} alt='clock' className='w-[14px] mr-3' />
													{parseInt(course.duration)} mins
												</span>
											</div>
										</div>

										<div className='p-8'>
											<p className='text-[16px] font-bold'>{course.title || 'Accounting Practices in Wiise'}</p>
										</div>
									</div>
								))
							) : (
								<div className='w-full md:w-1/3 h-[300px] bg-white rounded-[16px] overflow-hidden md:w-1/3-minus-gap-2 lg:w-1/3-minus-gap-2 animatePulse'></div>
							)}
						</div>
					</div>

					<FooterComponent />
				</div>
			) : (
				<LoadingScreen activeAccount={activeAccount} userEntitlements={['ft-01']} />
			)}
		</>
	)
}

export default AcademyPage
