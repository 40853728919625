import * as React from "react";
const CaretDown = (props) => (
  <svg
    width={8}
    height={8}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   
  >
    <g clipPath="url(#clip0_3301_252)">
      <path
        d="M6.5 3L4 5.5L1.5 3"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3301_252">
        <rect
          width={8}
          height={8}
          fill="white"
          transform="translate(8) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default CaretDown;
