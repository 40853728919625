import { useState, useEffect, useRef } from 'react'

const Stepper = ({ steps, currentStep }) => {
	const [newStep, setNewStep] = useState([])
	const stepRef = useRef()

	const updateStep = (stepNumber, steps) => {
		const newSteps = [...steps]
		let count = 0

		while (count < newSteps.length) {
			if (count === stepNumber) {
				newSteps[count] = {
					...newSteps[count],
					highlighted: true,
					active: true,
					completed: false,
				}
			} else if (count < stepNumber) {
				newSteps[count] = {
					...newSteps[count],
					highlighted: false,
					active: true,
					completed: true,
				}
			} else {
				newSteps[count] = {
					...newSteps[count],
					highlighted: false,
					active: false,
					completed: false,
				}
			}
			count++
		}

		return newSteps
	}

	useEffect(() => {
		const stepsState = steps.map((step, index) =>
			Object.assign(
				{},
				{
					description: step,
					currentStep: currentStep,
					// highlighted: index === 0 ? true : false,
					// active: index === 0 ? true : false,
					// completed: false,
				}
			)
		)

		stepRef.current = stepsState
		const current = updateStep(currentStep - 1, stepRef.current)
		setNewStep(current)
	}, [steps, currentStep])
	
	// const displayStep = newStep.map((step, index) => {
	// 	return (
	// 		<div key={index} className={''}>
	// 			<div className='flex flex-col item-center justify-center text-center w-[85vw] md:w-[75vw] lg:w-[65vw] xl:w-[850px]'>
	// 				{newStep.map ((step, index) =>(
	// 				<div>

	// 				<p className='text-[12px] text-white pb-[8px] font-bold'>{step.description}</p>
	// 				<div className="w-full h-[6px] rounded-full border mx-1"></div>
	// 				</div>))}
	// 			</div>
	// 		</div>
	// 	)
	// })

	return (
		<>
			<div className='' key={currentStep}>
				<div className='flex flex-row item-center justify-center text-center w-[85vw] md:w-[75vw] lg:w-[65vw] xl:w-[900px] space-x-[24px]'>
					{newStep.map((step, index) => (
						<div key={index}>
							{/* //step text */}
							<div className='w-[calc(85vw/2-12px)] md:w-[calc(75vw/2-12px)] lg:w-[calc(65vw/2-12px)] xl:w-[calc(900px/2-12px)]'>
								<p className='text-[12px] text-white pb-[8px] font-bold'>{step.description}</p>
							</div>
							{/* current step symbol */}
							{step.currentStep === index ? (
								<div className='h-[6px] rounded-full border  bg-white'></div>
							) : step.currentStep === 1 ? (
								<div className='h-[6px] rounded-full border bg-white'></div>
							) : (
								<div className='h-[6px] rounded-full border'></div>
							)}
						</div>
					))}
				</div>
			</div>
		</>
	)
}

export default Stepper
