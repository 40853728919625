

import { TickAndText } from "../assets/images"
const TickAndTextComp=({title='123'})=>{
	return(
		<div className="flex mt-[8px] items-baseline">
			<img src={TickAndText} alt='caretLeftSmallIconBlue'/>
			<p className="ml-[8px]">{title}</p>
		</div>
	)
}

export default TickAndTextComp