import * as React from "react";
const ThreeStarSVG= (props) => (
  <svg
    width={60}
    height={18}
    viewBox="0 0 60 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"

  >
    <path
      d="M29.6075 2.99747L25.1141 0.118645C24.5346 -0.250206 23.8214 0.298571 23.9907 0.973296L25.2924 6.13719C25.3276 6.28032 25.322 6.43058 25.2763 6.57065C25.2305 6.71071 25.1465 6.83489 25.0338 6.92887L21.0041 10.3205C20.4781 10.7613 20.7455 11.6519 21.432 11.6969L26.6921 12.0388C26.8356 12.0472 26.9736 12.0976 27.0892 12.1839C27.2048 12.2702 27.293 12.3886 27.3429 12.5246L29.3043 17.5086C29.3562 17.6526 29.4508 17.777 29.5752 17.8649C29.6996 17.9528 29.8479 18 29.9997 18C30.1516 18 30.2998 17.9528 30.4242 17.8649C30.5486 17.777 30.6432 17.6526 30.6951 17.5086L32.6565 12.5246C32.7065 12.3886 32.7947 12.2702 32.9103 12.1839C33.0258 12.0976 33.1638 12.0472 33.3074 12.0388L38.5675 11.6969C39.2539 11.6519 39.5214 10.7613 38.9954 10.3205L34.9656 6.92887C34.853 6.83489 34.769 6.71071 34.7232 6.57065C34.6775 6.43058 34.6719 6.28032 34.7071 6.13719L35.9107 1.35114C36.1157 0.541471 35.2598 -0.11526 34.5733 0.325561L30.392 2.99747C30.2748 3.07271 30.1387 3.11266 29.9997 3.11266C29.8608 3.11266 29.7247 3.07271 29.6075 2.99747Z"
      fill="#4575E5"
    />
    <path
      d="M5.74617 8.45293L2.8387 6.62095C2.46372 6.38623 2.00222 6.73545 2.11183 7.16482L2.95407 10.4509C2.97685 10.542 2.97323 10.6376 2.94363 10.7268C2.91403 10.8159 2.85966 10.8949 2.78678 10.9547L0.179278 13.113C-0.161081 13.3936 0.0119824 13.9603 0.45618 13.9889L3.85977 14.2065C3.95264 14.2119 4.04192 14.244 4.11671 14.2989C4.1915 14.3538 4.24855 14.4291 4.2809 14.5156L5.55003 17.6873C5.58363 17.7789 5.64483 17.8581 5.72533 17.914C5.80582 17.97 5.90172 18 6 18C6.09828 18 6.19417 17.97 6.27467 17.914C6.35517 17.8581 6.41637 17.7789 6.44996 17.6873L7.7191 14.5156C7.75145 14.4291 7.8085 14.3538 7.88329 14.2989C7.95807 14.244 8.04736 14.2119 8.14022 14.2065L11.5438 13.9889C11.988 13.9603 12.1611 13.3936 11.8207 13.113L9.21322 10.9547C9.14034 10.8949 9.08597 10.8159 9.05637 10.7268C9.02677 10.6376 9.02315 10.542 9.04593 10.4509L9.82471 7.40527C9.9574 6.89002 9.40359 6.4721 8.95939 6.75263L6.25383 8.45293C6.17796 8.50081 6.08991 8.52624 6 8.52624C5.91009 8.52624 5.82204 8.50081 5.74617 8.45293Z"
      fill="#4575E5"
    />
    <path
      d="M53.2003 8.45293L50.2928 6.62095C49.9178 6.38623 49.4563 6.73545 49.5659 7.16482L50.4082 10.4509C50.4309 10.542 50.4273 10.6376 50.3977 10.7268C50.3681 10.8159 50.3138 10.8949 50.2409 10.9547L47.6334 13.113C47.293 13.3936 47.4661 13.9603 47.9103 13.9889L51.3139 14.2065C51.4067 14.2119 51.496 14.244 51.5708 14.2989C51.6456 14.3538 51.7027 14.4291 51.735 14.5156L53.0041 17.6873C53.0377 17.7789 53.0989 17.8581 53.1794 17.914C53.2599 17.97 53.3558 18 53.4541 18C53.5524 18 53.6483 17.97 53.7288 17.914C53.8093 17.8581 53.8705 17.7789 53.9041 17.6873L55.1732 14.5156C55.2055 14.4291 55.2626 14.3538 55.3374 14.2989C55.4122 14.244 55.5015 14.2119 55.5943 14.2065L58.9979 13.9889C59.4421 13.9603 59.6152 13.3936 59.2748 13.113L56.6673 10.9547C56.5944 10.8949 56.5401 10.8159 56.5105 10.7268C56.4809 10.6376 56.4773 10.542 56.5 10.4509L57.2788 7.40527C57.4115 6.89002 56.8577 6.4721 56.4135 6.75263L53.7079 8.45293C53.6321 8.50081 53.544 8.52624 53.4541 8.52624C53.3642 8.52624 53.2761 8.50081 53.2003 8.45293Z"
      fill="#4575E5"
    />
  </svg>
);
export default ThreeStarSVG;
