import * as React from "react";
const MicrosoftLogoSVG = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.3348 11.8361H0V0.5H11.3348V11.8361Z" fill="#F15014" />
    <path d="M23.9999 11.8361H12.563V0.5H23.9999V11.8361Z" fill="#81CD23" />
    <path d="M11.3348 24.4997H0V13.0615H11.3348V24.4997Z" fill="#00AEEF" />
    <path d="M23.9999 24.4997H12.563V13.0615H23.9999V24.4997Z" fill="#FBBD02" />
  </svg>
);
export default MicrosoftLogoSVG;
