import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react'
import Smartlook from 'smartlook-client'

import PageNotFoundPage from './pages/PageNotFoundPage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import HomePage from './pages/HomePage'
import WelcomePage from './pages/WelcomePage'
import OnBoardingPage from './pages/OnBoardingPage'
import AccountOnBoardingPage from './pages/AccountOnBoardingPage'
import SupportPage from './pages/SupportPage'
import AcademyPage from './pages/AcademyPage'
// 📌 DISABLED: Until release
// 📌 ENABLES: For sales team only
import WiiseCopilotPage from './pages/WiiseCopilotPage'
// 📌 DISABLED: Until release
import WiisePayrollPage from './pages/WiisePayrollPage'
import ProductAndFeatures from './pages/ProductAndFeatures'
import WiisePayrollEnabled from './pages/WiisePayrollEnabled'
import WiisePayrollTermsAndConditions from './pages/WiisePayrollTermsAndConditions'

import MessageModal from './common/messageModal'
import LoadingScreen from './common/LoadingScreen'

import { dataRequest, getCustomerData } from './utils/dataRequest'

const WrappedView = () => {
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()

	const [trigger, setTrigger] = useState(false)

	const [isLoading, setIsLoading] = useState(true)
	const [errorType, setErrorType] = useState(false)

	const [isFormSubmitted, setIsFormSubmitted] = useState(null)
	const [isAccountFormSubmitted, setIsAccountFormSubmitted] = useState(null)

	const [isCustomer, setIsCustomer] = useState(null)
	const [contactEmail, setContactEmail] = useState('contactEmail')
	const [userEntitlements, setUserEntitlements] = useState([])

	// globalEntitleMentEnabled === false || Chargebee/API is down
	// Replace userEntitlements with allEnabledUserEntitlements
	// to make all entitlements available to all users
	// cc1011 - User Onboarding - enabled
	// cc1010 - Account Onboarding - disabled
	// ft-01 - Coassemble Widget - enabled
	// ft-02 - Copilot - enabled for sales team only
	// cc1004 - Command Centre: Business templates - enabled
	const allEnabledUserEntitlements = ['cc1011', 'ft-01', 'cc1004'] 
	const allDisabledUserEntitlements = ['cc1010'] 

	const fetchData = async () => {
		setIsLoading(true)

		try {
			if (activeAccount) {
				// Check if active user is a customer
				const customerData = await getCustomerData()
				setIsCustomer(customerData)

				if (
					customerData ===
					'{"error":"Customer with tenantId bed63c7f-08bf-4a19-afea-a32cb7a85ece not found in ChargeBee"}'
				) {
					setErrorType('permissionsError')
					setIsLoading(false)

					return
				} else if (customerData === 'Token is unauthorized.') {
					setErrorType('oopsError')
					setIsLoading(false)

					return
				}
			}

			if (activeAccount?.username) {
				// Check if active user has access and has submitted onboarding form
				// sanitize/normalize username/email
				let activeAccountEmail = (activeAccount?.username).toLowerCase()
				const response = await dataRequest(`KBIZ_HomePage_CheckHubSpotUser?email=${activeAccountEmail}`)

				if (
					response === 'Token is unauthorized.' &&
					isCustomer !==
						'{"error":"Customer with tenantId bed63c7f-08bf-4a19-afea-a32cb7a85ece not found in ChargeBee"}'
				) {
					setErrorType('configurationsError')
					setIsLoading(false)

					return
				} else if (response) {
					setContactEmail(response.contactEmail)
					setIsFormSubmitted(response.formSubmitted)

					// If the logged in user is first logged in user
					// isAccountFormSubmitted will be checked against value of accountOnBoarded
					if (response.firstLoggedInUser) {
						// True if filled and false if not filled
						setIsAccountFormSubmitted(response.accountOnboardingFormSubmitted)
					}
					// Impersonating code
					// else make the field true to assume its filled in UI
					// hence users who are ineligible to see the form will not see it
					// as UI thinks its filled by them
					// because the only conditions are true and false
					else {
						setIsAccountFormSubmitted(true)
					}

					try {
						const entitlementsResponse = await dataRequest('KBIZ_HomePage_GetEntitlements?entitlement_level=customer')

						if (entitlementsResponse.entitlementEnforcementRequired === true && entitlementsResponse.entitlements?.length > 0) {
							// Filter and extract all the enabled entitlement ids
							// const newEntitlements = entitlementsResponse.entitlements
                            //     .filter((entitlement) => entitlement.enabled === true)
                            //     .map((entitlement) => entitlement.id.toLowerCase())

							// ❗❗ FOR NOW ONLY ❗❗
							// ✅ Enable User Onbaording permanently, 
							// ✅ Enable Coassemble permanently,
							// ✅ Disable Account Onbaording Permanently 
							// ✅ Disable coplot permanently.
							// In other words, don't use the entitlement for now
							// Filter and extract all the enabled entitlement ids, excluding those in allDisabledUserEntitlements
							const newEntitlements = entitlementsResponse.entitlements
								.filter(entitlement => entitlement.enabled === true)
								.map(entitlement => entitlement.id.toLowerCase())
								.filter(id => !allDisabledUserEntitlements.includes(id))

							setUserEntitlements(newEntitlements)
						} else if (entitlementsResponse.entitlementEnforcementRequired === false || entitlementsResponse.entitlements?.length === 0) {
							setUserEntitlements(allEnabledUserEntitlements)
						}
					} catch (error) {
						console.error('Error fetching entitlements:', error.message)
					}
				} else {
					setErrorType('oopsError')

					throw new Error(`HTTP error! Status: ${response.status}`)
				}
			}
		} catch (error) {
			console.log('Error fetching data:', error.message)
		}

		setIsLoading(false)
	}

	useEffect(() => {
		var debugMode = process.env.REACT_APP_DEBUG_MODE
		// debugMode is false in production
		// The variable is in env file and azure environment variables.
		if (debugMode === 'false') {
			Smartlook.init(process.env.REACT_APP_SMARTLOOK_KEY)
		}
	}, [])

	useEffect(() => {
		fetchData()
	}, [activeAccount?.username, trigger])

	return (
		<>
			<AuthenticatedTemplate>
				{activeAccount && (
					<Routes>
						{isLoading ? (
							<Route path='*' element={<LoadingScreen activeAccount={activeAccount} />} />
						) : isCustomer === null ||
							!isCustomer?.tenantId ||
							(isCustomer && isCustomer.tenantId === '') ||
							(isCustomer?.error && isCustomer.error !== '') ||
							(contactEmail !== 'contactEmail' &&
									(contactEmail === '' || contactEmail === null || contactEmail === false)) ||
							errorType ? (
							<>
								<Route path='/' element={<MessageModal type={errorType} />} />
								<Route path='*' element={<MessageModal type={errorType} />} />
							</>
						) : (userEntitlements.includes('cc1011') || userEntitlements.includes('cc1010')) && 
							(userEntitlements.includes('cc1011') && (isFormSubmitted !== null && isFormSubmitted === false)) ? (
							<>
								{/* {console.log('📌 1', userEntitlements, isFormSubmitted, isAccountFormSubmitted)} */}
								<Route
									path='/onboarding'
									element={
										userEntitlements.includes('cc1011') ? (
											<OnBoardingPage
												userEntitlements={userEntitlements}
												contactEmail={contactEmail}
												oid={activeAccount.idTokenClaims?.oid}
												isAccountFormSubmitted={isAccountFormSubmitted}
												trigger={trigger}
												setTrigger={setTrigger}
											/>
										) : (
											<PageNotFoundPage />
										)
									}
								/>
								<Route
									path='/accountonboarding'
									element={
										userEntitlements.includes('cc1010') ? (
											<AccountOnBoardingPage
												userEntitlements={userEntitlements}
												contactEmail={contactEmail}
												oid={activeAccount.idTokenClaims?.oid}
												trigger={trigger}
												setTrigger={setTrigger}
											/>
										) : (
											<PageNotFoundPage />
										)
									}
								/>
								<Route
									path='/'
									element={
										(userEntitlements.includes('cc1011') && isFormSubmitted === false) ||
										(userEntitlements.includes('cc1010') && isAccountFormSubmitted === false) ? (
											<WelcomePage
												userEntitlements={userEntitlements}
												isFormSubmitted={isFormSubmitted}
												isAccountFormSubmitted={isAccountFormSubmitted}
											/>
										) : (
											<LoadingScreen activeAccount={activeAccount} />
										)
									}
								/>
								<Route path='/logout' element={<LogoutPage />} />
								<Route path='*' element={<PageNotFoundPage />} />
							</>
						) : (userEntitlements.includes('cc1011') || userEntitlements.includes('cc1010')) && 
							(userEntitlements.includes('cc1011') && (isFormSubmitted !== null && isFormSubmitted === true)) &&
							(userEntitlements.includes('cc1010') && (isAccountFormSubmitted !== null && isAccountFormSubmitted === false)) ? (
							<>
								{/* {console.log('📌 2', userEntitlements, isFormSubmitted, isAccountFormSubmitted)} */}
								<Route
									path='/accountonboarding'
									element={
										userEntitlements.includes('cc1010') ? (
											<AccountOnBoardingPage
												userEntitlements={userEntitlements}
												contactEmail={contactEmail}
												oid={activeAccount.idTokenClaims?.oid}
												trigger={trigger}
												setTrigger={setTrigger}
											/>
										) : (
											<PageNotFoundPage />
										)
									}
								/>
								<Route
									path='/'
									element={
										(userEntitlements.includes('cc1010') && isAccountFormSubmitted === false) ? (
											<WelcomePage
												userEntitlements={userEntitlements}
												isFormSubmitted={isFormSubmitted}
												isAccountFormSubmitted={isAccountFormSubmitted}
											/>
										) : (
											<LoadingScreen activeAccount={activeAccount} />
										)
									}
								/>
								<Route path='/logout' element={<LogoutPage />} />
								<Route path='*' element={<PageNotFoundPage />} />
							</>
						) : (userEntitlements.includes('cc1011') || 
							((userEntitlements.includes('cc1011') && isFormSubmitted !== null && isFormSubmitted === true) && !userEntitlements.includes('cc1010')) || 
							(!userEntitlements.includes('cc1011') && (isFormSubmitted === null || isFormSubmitted === false)) || 
							((userEntitlements.includes('cc1010') && isAccountFormSubmitted !== null && isAccountFormSubmitted === true)) || 
							(!userEntitlements.includes('cc1010') && (isAccountFormSubmitted === null || isAccountFormSubmitted === false)) || 
							(isFormSubmitted !== null && 
							isFormSubmitted === true &&
							isAccountFormSubmitted !== null && 
							isAccountFormSubmitted === true)) ? (
							<>
								{/* {console.log('📌 3', userEntitlements, isFormSubmitted, isAccountFormSubmitted)} */}
								{/* 📌 DISABLED: Until release */}
								{/* 📌 ENABLES: For sales team only */}
								<Route path='/wiise-copilot' element={userEntitlements.includes('ft-02') ? <WiiseCopilotPage /> : <PageNotFoundPage />} />
								<Route path='/academy' element={userEntitlements.includes('ft-01') ? <AcademyPage /> : <PageNotFoundPage />} /> 
								<Route path='/support' element={<SupportPage />} />

								<Route path='/product-features' element={<ProductAndFeatures userEntitlements={userEntitlements} />} />
								<Route path='/wiise-payroll' element={<WiisePayrollPage userEntitlements={userEntitlements} />} />
								<Route path='/wiise-payroll-enabled' element={<WiisePayrollEnabled userEntitlements={userEntitlements} />} />
								<Route path='/wiise-payroll-tcs' element={<WiisePayrollTermsAndConditions userEntitlements={userEntitlements} />} />


								<Route path='/' element={<HomePage userEntitlements={userEntitlements} />} />
								<Route path='/logout' element={<LogoutPage />} />
								<Route path='*' element={<PageNotFoundPage />} />
							</>
						) : (
							<>
								{/* {console.log('📌 4', userEntitlements, isFormSubmitted, isAccountFormSubmitted)} */}
								<Route path='*' element={<LoadingScreen activeAccount={activeAccount} />} />
							</>
						)}
					</Routes>
				)}
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
			<Routes>
					<Route path='/academy' element={<AcademyPage />} />
					<Route path='/' element={<LoginPage />} />
					<Route path='*' element={<PageNotFoundPage />} />
				</Routes>
			</UnauthenticatedTemplate>
		</>
	)
}

const App = ({ instance }) => {
	return (
		<MsalProvider instance={instance}>
			<WrappedView />
		</MsalProvider>
	)
}

export default App
