import HeaderComponent from '../common/headerComponent'
import { useMsal } from '@azure/msal-react'
import FooterComponent from '../common/footerComponent'
import { CaretSmallLeftSVG } from '../assets/images'
import FeatureCommonCard from '../common/featureCommonCard'
import ToggleButton from '../common/toggleButton'
import { useNavigate } from 'react-router-dom'
import { getEntitleMent, dataRequest, getFeatureFromHub} from '../utils/dataRequest'
import { useEffect, useState } from 'react'
import TailwindModal from '../tailwindModal'
const BASE_URL_CUSTOMER = process.env.REACT_APP_BASE_URL_CUSTOMER

const ProductAndFeatures = ({userEntitlements=[]})=>{
	const navigate = useNavigate()
	const { instance } = useMsal()
	const activeAccount = instance.getActiveAccount()
	console.log(activeAccount.tenantId)
	const bcUrl = JSON.parse(localStorage.getItem('bcUrl'))
	console.log(bcUrl)
	
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContents, setModalContents]=useState({
		modalTitle:'',
		modalMessage1:'',
		modalMessage2:'',
		modalButton1Title:'',
		modalButton2Title:'',
		modalButton1Func:()=>{},
		modalButton2Func:()=>{}

	})

  
	const closeModal = () => {
	  setIsModalOpen(false);
	};
  
	const CardBody=()=>{
		const [businessPlan, setBusinessPlan] = useState('Business')
		useEffect(()=>{
			fetchERPPlanData()
		},[])

		const fetchERPPlanData = async () => {
		const endpoint = `GetERPPlan?`
			const data = await dataRequest(endpoint, 'GET', null, BASE_URL_CUSTOMER )
			setBusinessPlan(data.name)

		}



		return(
			<div className='w-[100%]'>
			<div className={`flex flex-col p-[24px] mt-[24px] ${businessPlan=='Business'?'bg-wiiseHoverLightBlue border-wiisepBlue':'bg-wiisePrimaryWhite border-wiisetLightBlue wiisetLightBlue'} rounded-[16px] border border-wiisepBlue`}>
				<p className='font-mulish text-[16px] leading-[24px] font-bold text-wiisepNavy'>Business</p>
				<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px]'>{businessPlan=='Business'?'(Current plan)':'Financial management, inventory and warehousing, CRM, and projects.'}</p>
			</div>
			<div className={`flex flex-col p-[24px] mt-[24px] ${businessPlan=='Premium'?'bg-wiiseHoverLightBlue border-wiisepBlue':'bg-wiisePrimaryWhite border-wiisetLightBlue'} rounded-[16px] border`}>
				<p className='font-mulish text-[16px] leading-[24px] font-bold text-wiisepNavy'>Premium</p>
				{businessPlan=='Business'?
				<div className='flex flex-col sm:flex-row items-center mt-[0px] sm:mt-[8px]'>
					<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px] sm:mt-[0px] w-[100%]'>Everything in Business plus manufacturing and service management.</p>
					<div className='none sm:flex sm:flex-1'></div>
						<a className='flex text-wiisepBlue text-[16px] leading-[21px] font-bold mt-[12px] items-end justify-end w-[100%] sm:w-[45%] sm:mt-[0px]' href='https://www.wiise.com/contact' target="_blank" rel="noopener noreferrer">
						<p className=''>Talk to sales</p>
					</a>
				</div>:
				<div>
					<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px]'>(Current plan)</p>
				</div>
				}
			</div>
			</div>
		)
	}
	

	const CardFinanCialBody =()=>{
		const [userAdmin, setUserAdmin] = useState(false)
		const [payrollStatus, setPayrollStatus]= useState('')
		useEffect(()=>{
			fetchAdmin()
			getPayrollStatus()
		},[])
		const getPayrollStatus =async()=>{
			const results= await getFeatureFromHub()
			if(results.status=='enabled')
			{
				setPayrollStatus('enabled')
			}else if(results.status =='')
			{
				setPayrollStatus('')
			}else{
				setPayrollStatus('disabled')
			}
		}

		const fetchAdmin =async ()=>{
			let endPoint = 'CommandCentre_GetAuthorisedUser'
			const d= await dataRequest(endPoint, 'GET', null)
			if(d.contactTypes.some((i)=>i.includes("Wiise Business Administrator")))
			{
				setUserAdmin(true)
			}else{
				setUserAdmin(false)
			}
			
			// if
		}
		
		const contactFunc=()=>{
			window.location.href = 'mailto:support@wiise.com';
		}
		const removeWiisePayrollFunction=()=>{
			if(userAdmin)
			{
			setModalContents({...modalContents,
				 modalTitle:'Remove Wiise Payroll', 
				 modalMessage1:'If you would like to remove Wiise Payroll, please contact Wiise support.', 
				 modalButton1Title:'Contact',
				 modalButton2Title:'Cancel',
				 modalButton1Func:()=>contactFunc(),
				 modalButton2Func:()=>closeModal()
				})
				setIsModalOpen(true)
			}else{
				setModalContents({...modalContents,
					modalTitle:'Contact your administrator', 
					modalMessage1:'You do not have permission to disable payroll on this account. ', 
					modalMessage2:'Please contact your administrator if you would like to disable the feature.',
					modalButton1Title:'OK',
					modalButton1Func:()=>closeModal(),
				   })
				   setIsModalOpen(true)
	
			}

		}
		const showWarningForNonAdmin =()=>{
			setModalContents({...modalContents,
				modalTitle:'Contact your administrator', 
				modalMessage1:'You do not have permission to enable payroll on this account. ', 
				modalMessage2:'Please contact your administrator if you would like to enable the feature.',
				modalButton1Title:'OK',
				modalButton1Func:()=>closeModal(),
			   })
			   setIsModalOpen(true)

		}
		return(
			<div className='w-[100%]'>
				{/* <div className='flex flex-col p-[24px] mt-[24px] bg-wiiseHoverLightBlue rounded-[16px] border border-wiisepBlue'>
					<div className='flex flex-row w-[100%] items-center'>
						<p className='font-mulish text-[16px] leading-[24px] font-bold text-wiisepNavy'>Wiise Bank Feeds</p>
						<div className='flex flex-1'></div>
						<ToggleButton status='on'/>
					</div>
					<p className='text-wiisepNavy font-normal text-[14px] leading-[21px] font-mulish mt-[8px]'>Automatically import your bank transactions into your ERP.</p>
					<p className='flex text-wiisepBlue text-[14px] leading-[21px] font-bold mt-[12px]'>View set up</p>
				</div> */}
				{payrollStatus =='enabled'?
				<div className={`flex flex-col p-[24px] mt-[24px] bg-wiiseHoverLightBlue rounded-[16px] border border-wiisepBlue`}>
				<div className='flex flex-row w-[100%] items-center'>
					<p className='font-mulish text-[16px] leading-[24px] font-bold text-wiisepNavy'>Wiise Payroll</p>
					<div className='flex flex-1'></div>
					<ToggleButton status='on' enabled={userAdmin} handleAction={()=>removeWiisePayrollFunction()}/>
				</div>
				<p className='text-wiisepNavy font-normal text-[14px] leading-[21px] font-mulish mt-[8px]'>Automate your payroll and streamline how you pay your people.</p>
				<a href={`${bcUrl}?page=16034642`}>
				<p className='flex text-wiisepBlue text-[14px] leading-[21px] font-bold mt-[12px] cursor-pointer' onClick={()=>console.log('1')}>View set up</p>
				</a>
			</div>:
				<div className={`flex flex-col p-[24px] mt-[24px] bg-wiisePrimaryWhite: rounded-[16px] border border-wiisetLightBlue`}>
					<p className='font-mulish text-[16px] leading-[24px] font-bold text-wiisepNavy'>Wiise Payroll</p>
					<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px]'>Automate your payroll and streamline how you pay your people.</p>
					<div className='flex flex-col sm:flex-row items-center mt-[0px] sm:mt-[8px]'>
						<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px] sm:mt-[0px] w-[100%]'>AU$4 per active employee, per pay run.</p>
						<div className='none sm:flex sm:flex-1'></div>
						<p onClick={()=> 
						{
							if(userAdmin)
								{
									navigate('/wiise-payroll')
								}else{
									showWarningForNonAdmin()
								}
							}
						} className={`flex ${userAdmin?'text-wiisepBlue':'text-wiisetLightBlue'} cursor-pointer text-[16px] leading-[21px] font-bold mt-[12px] items-end justify-end w-[100%] sm:w-[45%] sm:mt-[0px]`}>Add Wiise Payroll</p>
					</div>
				</div>}


			</div>
		)
	}
	const cardSupplyChainBody=()=>{
		return(
			<div className='w-[100%]'>
				<div className='flex flex-col p-[24px] mt-[24px] bg-wiisePrimaryWhite rounded-[16px] border border-wiisepBlue'>
					<p className='font-mulish text-[16px] leading-[24px] font-bold text-wiisepNavy'>Wiise Warehouse OnTime</p>
					<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px]'>Digitize your warehouse operations.</p>
					<div className='flex flex-col sm:flex-row items-center mt-[0px] sm:mt-[8px]'>
						<p className='font-mulish text-[14px] leading-[21px] font-normal text-wiisepNavy mt-[8px] sm:mt-[0px] w-[100%]'>Included in your Wiise plan.</p>
						<div className='none sm:flex sm:flex-1'></div>
						<p className='flex text-wiisepBlue text-[16px] leading-[21px] font-bold mt-[12px] items-end justify-end w-[100%] sm:w-[85%] sm:mt-[0px]'>Add Wiise Warehouse OnTime</p>
					</div>
				</div>
			</div>
		)
	}
	return(
		<>
		<HeaderComponent userEntitlements={userEntitlements} />
		{/* <MessageModal/> */}
		<div className=' my-[80px] sm:my-[100px] lg:mx-21pct md:my-[120px] lg:my-[120px]'>
			<a className='flex items-baseline' href='/'>
				<img src={CaretSmallLeftSVG} alt='caretLeftSmallIconBlue'/> <p className='text-wiisepBlue text-[12px] ml-[8px] leading-[18px] font-bold cursor-pointer hover:underline'>Back to dashboard</p>
			</a>

      	<TailwindModal 
			isOpen={isModalOpen}
			button2Title={modalContents.modalButton2Title} 
			button1Title={modalContents.modalButton1Title}
			button2Action={modalContents.modalButton2Func}
			button1Action={modalContents.modalButton1Func} 
			title={modalContents.modalTitle} 
			message1={modalContents.modalMessage1} 
			message2={modalContents.modalMessage2}
		/>
			<div className='text-[22px] leading-[33px] sm:text-[24px] sm:leading-[36px] mt-[16px] text-wiisepNavy font-bold font-modernEra '>
				Product and features
			</div>
			<div className='text-[14px] leading-[21px] font-mulish font-normal text-wiisepNavy mt-[24px] sm:mt-[40px]'>
				Select products and add-ons.
			</div>
			<FeatureCommonCard cardMainTitle='Your whole business' cardSubTitle='Wiise ERP' cardBody={CardBody}/>
			<FeatureCommonCard cardMainTitle='Your financials' cardBody={CardFinanCialBody}/>
			{/* <FeatureCommonCard cardMainTitle='Your supply chain' cardBody={cardSupplyChainBody} /> */}
		</div>
		<FooterComponent />
		</>
	)
}
export default ProductAndFeatures