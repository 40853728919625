import { LogLevel } from '@azure/msal-browser'

// Browser check variables
// If you support IE, recommend that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_CLIENT_ID || '',
		authority: process.env.REACT_APP_AUTHORITY || '',
		// Should point to window.location.origin or "/". You must register this redirect URIs on Azure Portal/App Registration.
		redirectUri: window.location.href, // Capture the current URL or // process.env.REACT_APP_REDIRECT_URI || window.location.origin,
		postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
		navigateToLoginRequestUrl: false,
	},
	cache: {
		// Configures cache location. 
		// NOTE: "sessionStorage" may be more secure,
		// but "localStorage" gives you SSO between tabs.
		cacheLocation: 'localStorage',
		// Set this to "true" if you are still having issues on IE11 or Edge
		storeAuthStateInCookie: false,
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return
				}
				switch (level) {
					// Uncomment the logging levels as needed
					// case LogLevel.Error:
					// 	console.error(message)
					// 	return
					// case LogLevel.Info:
					// 	console.info(message)
					// 	return
					// case LogLevel.Verbose:
					// 	console.debug(message)
					// 	return
					// case LogLevel.Warning:
					// 	console.warn(message)
					// 	return
					// default:
					// 	return
				}
			},
		},
	},
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read'],
    prompt: 'select_account' // This line will ensure the account selection prompt appears every time
}

// Add here the endpoints for MS Graph API services you would like to use.
// 📌 TODO: Refactor to use the below instead of old method and remove this comment when done.
export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
