import * as React from "react";
const WIISEOntimeSVG= (props) => (
  <svg
    width={27}
    height={18}
    viewBox="0 0 27 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7116 2.06201C13.0861 1.20477 13.6984 0 14.7596 0H19.6836C20.0979 0 20.4873 0.197898 20.7315 0.532581L26.3524 8.23528C26.6849 8.69089 26.6849 9.30911 26.3524 9.76472L20.7315 17.4674C20.4873 17.8021 20.0979 18 19.6836 18H14.7596C13.6984 18 13.0861 16.7952 13.7116 15.938L18.2164 9.76472C18.5489 9.30911 18.5489 8.69089 18.2164 8.23528L13.7116 2.06201Z"
      fill="#4575E5"
    />
    <path
      d="M0.25167 2.06201C-0.373885 1.20477 0.238397 0 1.29962 0H6.22362C6.63794 0 7.02734 0.197898 7.27157 0.532581L12.8925 8.23528C13.2249 8.69089 13.2249 9.30911 12.8925 9.76472L7.27157 17.4674C7.02734 17.8021 6.63794 18 6.22362 18H1.29961C0.238394 18 -0.373885 16.7952 0.251671 15.938L4.75649 9.76472C5.08896 9.30911 5.08896 8.69089 4.75649 8.23528L0.25167 2.06201Z"
      fill="#4575E5"
    />
    <path
      d="M13.7116 2.06201C13.0861 1.20477 13.6984 0 14.7596 0H19.6836C20.0979 0 20.4873 0.197898 20.7315 0.532581L26.3524 8.23528C26.6849 8.69089 26.6849 9.30911 26.3524 9.76472L20.7315 17.4674C20.4873 17.8021 20.0979 18 19.6836 18H14.7596C13.6984 18 13.0861 16.7952 13.7116 15.938L18.2164 9.76472C18.5489 9.30911 18.5489 8.69089 18.2164 8.23528L13.7116 2.06201Z"
      fill="url(#paint0_linear_3290_3877)"
    />
    <path
      d="M0.25167 2.06201C-0.373885 1.20477 0.238397 0 1.29962 0H6.22362C6.63794 0 7.02734 0.197898 7.27157 0.532581L12.8925 8.23528C13.2249 8.69089 13.2249 9.30911 12.8925 9.76472L7.27157 17.4674C7.02734 17.8021 6.63794 18 6.22362 18H1.29961C0.238394 18 -0.373885 16.7952 0.251671 15.938L4.75649 9.76472C5.08896 9.30911 5.08896 8.69089 4.75649 8.23528L0.25167 2.06201Z"
      fill="url(#paint1_linear_3290_3877)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3290_3877"
        x1={26.9637}
        y1={9.08108}
        x2={12.2069}
        y2={9.07692}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50B8F9" />
        <stop offset={0.260417} stopColor="#4575E5" stopOpacity={0.963542} />
        <stop offset={1} stopColor="#4575E5" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3290_3877"
        x1={13.5037}
        y1={9.08108}
        x2={-1.25304}
        y2={9.07692}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50B8F9" />
        <stop offset={0.260417} stopColor="#4575E5" stopOpacity={0.963542} />
        <stop offset={1} stopColor="#4575E5" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default WIISEOntimeSVG
;
